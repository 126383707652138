//my advsior contact info
.myadvisor {
    background-color: $darker;
    width: 100%;

    .myadvisor-wrapper {
        display: flex;
        flex-wrap: wrap;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        } 

        .myadvisor-item {
            display: flex;
            flex-direction: column;
            padding: 15px 1rem; 
            & ~ .myadvisor-item {
                flex: 1 0 0%;
                @include media-breakpoint-down(sm) {
                    flex: 1 0 0;
                    width: 100%;
                    border-top: 1px solid rgba($white, 0.25);
                }            
            }

            .header {
                display: flex;
                align-items: center;
                @media only screen and (max-width: 375px) {
                    align-items: flex-start;
                    flex-direction: column;
                }
                
                .avatar {
                    text-align: center;
                    margin-right: .5rem;
                    @include media-breakpoint-down(sm) {
                        align-items: flex-start;
                        flex-direction: column;
                        margin-bottom: 5px;
                    }
    
                    .avatar-img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        width: 95px;
                        height: 95px;
                        border-radius: 50%;
                        border: 4px solid $gray-light;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-color: $primary-blue;
                        @include media-breakpoint-down(sm) {
                            width: 75px;
                            height: 75px;
                        }
    
                        > i {
                            font-size: 2.5rem;
                            color: $white;
                        }
                    }
    
                    .avatar-social {
                        font-size: 1.75rem;
                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }
                }

                .info {
                    .name {
                        line-height: 1;
                        text-transform: capitalize;
                        color: $white;
                        display: block;
                        font-weight: $font-weight-bolder;
                    }
                    .title {
                        display: block;
                        color: $light;
                        font-size: $font-size-xs;
                    }
                    .handles {
                        margin-top: 3px;
                        > a {
                            > i { 
                                font-size: 1.425rem;
                            }
                        }
                    }
                }
            }


            .details {
                padding-left: .5rem;
                color: $white;
                @include media-breakpoint-down(sm) {
                    padding-left: 0;
                }
                .contact-info {
                    margin-top: 10px;
                    @include media-breakpoint-down(sm) {
                        margin-top: 5px;
                    }
                    > a {
                        display: block;
                        margin-bottom: 2px;
                        @include text-truncate();
                        color: $white;
                        > i {
                            color: $primary-blue;
                            font-size: 1.25rem;
                            vertical-align: middle;
                            margin-right: 3px;
                        }
                        > span {
                            color: $white;
                            font-size: $font-size-sm;
                        }
                    }
                }
            }
        }
    }
}

//my advisor help card
.advisor-help {
	background-color: $light;
	padding: 30px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	
	@include media-breakpoint-down(md) {
		flex-direction: column;
		justify-content: center;
	}

	.advisor-help-start {
		display: flex;
		align-items: center;
		padding-right: 15px;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			justify-content: center;
			text-align: center;
			> div {
				margin-bottom: 15px;
			}
		}

		> i {
			width: 56px;
			height: 56px;
			line-height: 56px;
			border-radius: 50%;
			text-align: center;
			background-color: $primary-blue-100;
			color: $primary-blue;
			margin-right: 10px;
			font-size: 2rem;
		}
	}
	
	.advisor-help-end {
		> .btn {
			margin: 10px 0;
		}
	}
}