// Container mixins

@mixin make-container($gutter: $container-padding-x) {
  width: 100%;
  padding-right: 60px;
  padding-left: 60px;
  margin-right: auto;
  margin-left: auto;
  @include media-breakpoint-down(md) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @include media-breakpoint-down(sm) {
    padding-right: 15px;
    padding-left: 15px;
  }
}
