//
// Base styles
//

.btn {
	display: inline-block;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	line-height: $btn-line-height;
	color: $white;
	text-transform: uppercase;
	text-align: center;
	text-decoration: if($link-decoration==none, null, none);
	white-space: $btn-white-space;
	vertical-align: middle;
	cursor: if($enable-button-pointers, pointer, null);
	user-select: none;
	background-color: $primary-blue;
	min-height: $input-btn-height;
	max-height: $input-btn-height;
	border: $btn-border-width solid transparent;
	@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
	@include transition($btn-transition);

	&:hover {
		color: $white;
		background-color: $primary-blue-300;
		text-decoration: if($link-hover-decoration==underline, none, null);
	}

	.btn-check:focus+&,
	&:focus {
		outline: 0;
		box-shadow: $btn-focus-box-shadow;
	}

	.btn-check:checked+&,
	.btn-check:active+&,
	&:active,
	&.active {
		@include box-shadow($btn-active-box-shadow);

		&:focus {
			@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
		}
	}

	&:disabled,
	&.disabled,
	fieldset:disabled & {
		pointer-events: none;
		opacity: $btn-disabled-opacity;
		@include box-shadow(none);
	}
	&.btn-tight {
		padding: $btn-padding-y calc(#{$btn-padding-x} / 1.5);
	}
}

//outline
.btn-outline {
	@extend .btn;
	background-color: $white;
	border: $btn-border-width solid $primary-blue-200;
	color: $primary-blue;

	&:hover {
		color: $primary-blue;
		background-color: $primary-blue-100;
		border-color: $primary-blue-300;
	}
}

.btn-outline-secondary {
	@extend .btn;
	background-color: $white;
	border: $btn-border-width solid $primary-blue-200;
	color: $primary-blue;

	&:hover {
		color: $primary-blue-300;
		background-color: transparent;
		border-color: $primary-blue-200;
	}
}


//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
// @each $color, $value in $theme-colors {
//   .btn-#{$color} {
//     @include button-variant($value, $value);
//   }
// }

// @each $color, $value in $theme-colors {
//   .btn-outline-#{$color} {
//     @include button-outline-variant($value);
//   }
// }
// scss-docs-end btn-variant-loops


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	color: $btn-link-color;
	text-decoration: $link-decoration;
	text-transform: uppercase;
	background-color: transparent;
	border: none;
	white-space: $btn-white-space;

	&:hover {
		color: $btn-link-hover-color;
		text-decoration: $link-hover-decoration;
	}

	&:focus {
		text-decoration: $link-hover-decoration;
	}

	&:disabled,
	&.disabled {
		color: $btn-link-disabled-color;
	}

	// No need for an active state here
}

.btn-link-sm {
	font-size: $btn-font-size-sm;
}
.btn-link-lg {
	font-size: $btn-font-size-lg;
}


//
// Button Sizes
//

.btn-lg {
	@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
	min-height: $input-btn-height-lg;
	max-height: $input-btn-height-lg;
}

.btn-sm {
	@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
	min-height: $input-btn-height-sm;
	max-height: $input-btn-height-sm;
}

//title link
.title-link {
	color: $dark;

	&:hover,
	&:focus {
		color: $primary-blue;

		>.i-circle-rounded {
			@include i-circle-rounded-hover;
		}
	}
}

.close-btn {
	cursor: pointer;
	&:hover {
		> i {
			@include i-circle-rounded-hover;
		}
	}
}

//floating talk to my advisor
.btn-ttmadvisor {
	position: fixed;
	z-index: 888;
	bottom: 60px;
	right: 30px;
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.cursor-pointer {
	cursor: pointer;
}