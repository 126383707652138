.main-wrapper {
    display: flex;

    .inner-wrapper {
        width: 100%;
        margin-top: $nav-header-height;
        margin-left: $aside-nav-width;

        @include media-breakpoint-down(md) {
            margin-top: $nav-header-height-mobile;
            margin-left: 0;
        }

        .content-wrapper {
            @include content-wrapper();
            width: 100%;
            max-width: 1600px;
            margin: 0 auto;
        }

        .page-header {
            @include content-wrapper();
            width: 100%;
            height: 75px;
            background-color: $white;
            border-bottom: 1px solid $border-color;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include media-breakpoint-down(md) {
                height: 55px;
            }
            > h1 {
                color: $dark;
                font-size: $h3-font-size;
                margin-bottom: 0;
                @include media-breakpoint-down(md) {
                    font-size: $h5-font-size;
                }
            }
        }
        
        .small-header {
            @include content-wrapper();
            width: 100%;
            height: 60px;
            background-color: $white;
            border-bottom: 1px solid $border-color;
            display: flex;
            align-items: center;
            @include media-breakpoint-down(md) {
                height: 55px;
            }
            h3 {
                color: $dark;
                font-weight: $font-weight-bolder;
                font-size: $h6-font-size;
                margin-bottom: 0;
            }

            > a {
                display: flex;
                align-items: center;
            }
        }

        .subpage-wrapper {
            display: flex;
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
            .subpage-aside {
                height: 100%;
                width: 100%;
                max-width: 200px;
                @include media-breakpoint-down(md) {
                    max-width: unset;
                }
            }
            > section {
                width: 100%;
                border-left: 1px solid $border-color;
                @include media-breakpoint-down(md) {
                    border-left: none;
                    border-top: 1px solid $border-color;
                }
            }
        }
    }
}