.exc-rate {
    display: flex;
    align-items: center;
    &-toggle {
        cursor: pointer;
    }
    &-title {
        display: block;
        line-height: 1;
    }
    &-container {
        position: relative;
        margin-left: 10px;
        display: flex;
        align-items: center;
        > i {
            margin: 0 3px;
        }
    }
    &-value {
        margin-left: 10px;
        font-weight: $font-weight-bolder;
        font-size: $small-font-size;
    }
}