//background colors
@each $color,$value in $colors {
    .color-#{$color} {
        color: $value !important;        
    }
}
//background colors
@each $color,$value in $colors {
    .bgcolor-#{$color} {
        background-color: $value !important;        
    }
}