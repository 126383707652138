@charset "UTF-8";
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 */
.fs-xxlarge {
  font-size: 1.5rem !important; }

@media (min-width: 577px) {
  .fs-xxlarge-sm {
    font-size: 1.5rem !important; } }

@media (min-width: 769px) {
  .fs-xxlarge-md {
    font-size: 1.5rem !important; } }

@media (min-width: 993px) {
  .fs-xxlarge-lg {
    font-size: 1.5rem !important; } }

@media (min-width: 1201px) {
  .fs-xxlarge-xl {
    font-size: 1.5rem !important; } }

@media (min-width: 1401px) {
  .fs-xxlarge-xxl {
    font-size: 1.5rem !important; } }

.fs-xlarge {
  font-size: 1.25rem !important; }

@media (min-width: 577px) {
  .fs-xlarge-sm {
    font-size: 1.25rem !important; } }

@media (min-width: 769px) {
  .fs-xlarge-md {
    font-size: 1.25rem !important; } }

@media (min-width: 993px) {
  .fs-xlarge-lg {
    font-size: 1.25rem !important; } }

@media (min-width: 1201px) {
  .fs-xlarge-xl {
    font-size: 1.25rem !important; } }

@media (min-width: 1401px) {
  .fs-xlarge-xxl {
    font-size: 1.25rem !important; } }

.fs-large {
  font-size: 1.125rem !important; }

@media (min-width: 577px) {
  .fs-large-sm {
    font-size: 1.125rem !important; } }

@media (min-width: 769px) {
  .fs-large-md {
    font-size: 1.125rem !important; } }

@media (min-width: 993px) {
  .fs-large-lg {
    font-size: 1.125rem !important; } }

@media (min-width: 1201px) {
  .fs-large-xl {
    font-size: 1.125rem !important; } }

@media (min-width: 1401px) {
  .fs-large-xxl {
    font-size: 1.125rem !important; } }

.fs-default {
  font-size: 1rem !important; }

@media (min-width: 577px) {
  .fs-default-sm {
    font-size: 1rem !important; } }

@media (min-width: 769px) {
  .fs-default-md {
    font-size: 1rem !important; } }

@media (min-width: 993px) {
  .fs-default-lg {
    font-size: 1rem !important; } }

@media (min-width: 1201px) {
  .fs-default-xl {
    font-size: 1rem !important; } }

@media (min-width: 1401px) {
  .fs-default-xxl {
    font-size: 1rem !important; } }

.fs-small {
  font-size: 0.875rem !important; }

@media (min-width: 577px) {
  .fs-small-sm {
    font-size: 0.875rem !important; } }

@media (min-width: 769px) {
  .fs-small-md {
    font-size: 0.875rem !important; } }

@media (min-width: 993px) {
  .fs-small-lg {
    font-size: 0.875rem !important; } }

@media (min-width: 1201px) {
  .fs-small-xl {
    font-size: 0.875rem !important; } }

@media (min-width: 1401px) {
  .fs-small-xxl {
    font-size: 0.875rem !important; } }

.fs-xsmall {
  font-size: 0.75rem !important; }

@media (min-width: 577px) {
  .fs-xsmall-sm {
    font-size: 0.75rem !important; } }

@media (min-width: 769px) {
  .fs-xsmall-md {
    font-size: 0.75rem !important; } }

@media (min-width: 993px) {
  .fs-xsmall-lg {
    font-size: 0.75rem !important; } }

@media (min-width: 1201px) {
  .fs-xsmall-xl {
    font-size: 0.75rem !important; } }

@media (min-width: 1401px) {
  .fs-xsmall-xxl {
    font-size: 0.75rem !important; } }

.spacer-0 {
  display: block;
  width: 100%;
  height: 0px !important; }

.spacer-5 {
  display: block;
  width: 100%;
  height: 5px !important; }

.spacer-10 {
  display: block;
  width: 100%;
  height: 10px !important; }

.spacer-15 {
  display: block;
  width: 100%;
  height: 15px !important; }

.spacer-20 {
  display: block;
  width: 100%;
  height: 20px !important; }

.spacer-25 {
  display: block;
  width: 100%;
  height: 25px !important; }

.spacer-30 {
  display: block;
  width: 100%;
  height: 30px !important; }

.spacer-40 {
  display: block;
  width: 100%;
  height: 40px !important; }

.spacer-50 {
  display: block;
  width: 100%;
  height: 50px !important; }

.spacer-60 {
  display: block;
  width: 100%;
  height: 60px !important; }

.spacer-70 {
  display: block;
  width: 100%;
  height: 70px !important; }

.spacer-80 {
  display: block;
  width: 100%;
  height: 80px !important; }

.spacer-90 {
  display: block;
  width: 100%;
  height: 90px !important; }

.spacer-100 {
  display: block;
  width: 100%;
  height: 100px !important; }

.spacer-150 {
  display: block;
  width: 100%;
  height: 150px !important; }

.spacer-200 {
  display: block;
  width: 100%;
  height: 200px !important; }

.spacer-250 {
  display: block;
  width: 100%;
  height: 250px !important; }

@media (min-width: 577px) {
  .spacer-sm-0 {
    display: block;
    width: 100%;
    height: 0px !important; }
  .spacer-sm-5 {
    display: block;
    width: 100%;
    height: 5px !important; }
  .spacer-sm-10 {
    display: block;
    width: 100%;
    height: 10px !important; }
  .spacer-sm-15 {
    display: block;
    width: 100%;
    height: 15px !important; }
  .spacer-sm-20 {
    display: block;
    width: 100%;
    height: 20px !important; }
  .spacer-sm-25 {
    display: block;
    width: 100%;
    height: 25px !important; }
  .spacer-sm-30 {
    display: block;
    width: 100%;
    height: 30px !important; }
  .spacer-sm-40 {
    display: block;
    width: 100%;
    height: 40px !important; }
  .spacer-sm-50 {
    display: block;
    width: 100%;
    height: 50px !important; }
  .spacer-sm-60 {
    display: block;
    width: 100%;
    height: 60px !important; }
  .spacer-sm-70 {
    display: block;
    width: 100%;
    height: 70px !important; }
  .spacer-sm-80 {
    display: block;
    width: 100%;
    height: 80px !important; }
  .spacer-sm-90 {
    display: block;
    width: 100%;
    height: 90px !important; }
  .spacer-sm-100 {
    display: block;
    width: 100%;
    height: 100px !important; }
  .spacer-sm-150 {
    display: block;
    width: 100%;
    height: 150px !important; }
  .spacer-sm-200 {
    display: block;
    width: 100%;
    height: 200px !important; }
  .spacer-sm-250 {
    display: block;
    width: 100%;
    height: 250px !important; } }

@media (min-width: 769px) {
  .spacer-md-0 {
    display: block;
    width: 100%;
    height: 0px !important; }
  .spacer-md-5 {
    display: block;
    width: 100%;
    height: 5px !important; }
  .spacer-md-10 {
    display: block;
    width: 100%;
    height: 10px !important; }
  .spacer-md-15 {
    display: block;
    width: 100%;
    height: 15px !important; }
  .spacer-md-20 {
    display: block;
    width: 100%;
    height: 20px !important; }
  .spacer-md-25 {
    display: block;
    width: 100%;
    height: 25px !important; }
  .spacer-md-30 {
    display: block;
    width: 100%;
    height: 30px !important; }
  .spacer-md-40 {
    display: block;
    width: 100%;
    height: 40px !important; }
  .spacer-md-50 {
    display: block;
    width: 100%;
    height: 50px !important; }
  .spacer-md-60 {
    display: block;
    width: 100%;
    height: 60px !important; }
  .spacer-md-70 {
    display: block;
    width: 100%;
    height: 70px !important; }
  .spacer-md-80 {
    display: block;
    width: 100%;
    height: 80px !important; }
  .spacer-md-90 {
    display: block;
    width: 100%;
    height: 90px !important; }
  .spacer-md-100 {
    display: block;
    width: 100%;
    height: 100px !important; }
  .spacer-md-150 {
    display: block;
    width: 100%;
    height: 150px !important; }
  .spacer-md-200 {
    display: block;
    width: 100%;
    height: 200px !important; }
  .spacer-md-250 {
    display: block;
    width: 100%;
    height: 250px !important; } }

@media (min-width: 993px) {
  .spacer-lg-0 {
    display: block;
    width: 100%;
    height: 0px !important; }
  .spacer-lg-5 {
    display: block;
    width: 100%;
    height: 5px !important; }
  .spacer-lg-10 {
    display: block;
    width: 100%;
    height: 10px !important; }
  .spacer-lg-15 {
    display: block;
    width: 100%;
    height: 15px !important; }
  .spacer-lg-20 {
    display: block;
    width: 100%;
    height: 20px !important; }
  .spacer-lg-25 {
    display: block;
    width: 100%;
    height: 25px !important; }
  .spacer-lg-30 {
    display: block;
    width: 100%;
    height: 30px !important; }
  .spacer-lg-40 {
    display: block;
    width: 100%;
    height: 40px !important; }
  .spacer-lg-50 {
    display: block;
    width: 100%;
    height: 50px !important; }
  .spacer-lg-60 {
    display: block;
    width: 100%;
    height: 60px !important; }
  .spacer-lg-70 {
    display: block;
    width: 100%;
    height: 70px !important; }
  .spacer-lg-80 {
    display: block;
    width: 100%;
    height: 80px !important; }
  .spacer-lg-90 {
    display: block;
    width: 100%;
    height: 90px !important; }
  .spacer-lg-100 {
    display: block;
    width: 100%;
    height: 100px !important; }
  .spacer-lg-150 {
    display: block;
    width: 100%;
    height: 150px !important; }
  .spacer-lg-200 {
    display: block;
    width: 100%;
    height: 200px !important; }
  .spacer-lg-250 {
    display: block;
    width: 100%;
    height: 250px !important; } }

@media (min-width: 1201px) {
  .spacer-xl-0 {
    display: block;
    width: 100%;
    height: 0px !important; }
  .spacer-xl-5 {
    display: block;
    width: 100%;
    height: 5px !important; }
  .spacer-xl-10 {
    display: block;
    width: 100%;
    height: 10px !important; }
  .spacer-xl-15 {
    display: block;
    width: 100%;
    height: 15px !important; }
  .spacer-xl-20 {
    display: block;
    width: 100%;
    height: 20px !important; }
  .spacer-xl-25 {
    display: block;
    width: 100%;
    height: 25px !important; }
  .spacer-xl-30 {
    display: block;
    width: 100%;
    height: 30px !important; }
  .spacer-xl-40 {
    display: block;
    width: 100%;
    height: 40px !important; }
  .spacer-xl-50 {
    display: block;
    width: 100%;
    height: 50px !important; }
  .spacer-xl-60 {
    display: block;
    width: 100%;
    height: 60px !important; }
  .spacer-xl-70 {
    display: block;
    width: 100%;
    height: 70px !important; }
  .spacer-xl-80 {
    display: block;
    width: 100%;
    height: 80px !important; }
  .spacer-xl-90 {
    display: block;
    width: 100%;
    height: 90px !important; }
  .spacer-xl-100 {
    display: block;
    width: 100%;
    height: 100px !important; }
  .spacer-xl-150 {
    display: block;
    width: 100%;
    height: 150px !important; }
  .spacer-xl-200 {
    display: block;
    width: 100%;
    height: 200px !important; }
  .spacer-xl-250 {
    display: block;
    width: 100%;
    height: 250px !important; } }

@media (min-width: 1401px) {
  .spacer-xxl-0 {
    display: block;
    width: 100%;
    height: 0px !important; }
  .spacer-xxl-5 {
    display: block;
    width: 100%;
    height: 5px !important; }
  .spacer-xxl-10 {
    display: block;
    width: 100%;
    height: 10px !important; }
  .spacer-xxl-15 {
    display: block;
    width: 100%;
    height: 15px !important; }
  .spacer-xxl-20 {
    display: block;
    width: 100%;
    height: 20px !important; }
  .spacer-xxl-25 {
    display: block;
    width: 100%;
    height: 25px !important; }
  .spacer-xxl-30 {
    display: block;
    width: 100%;
    height: 30px !important; }
  .spacer-xxl-40 {
    display: block;
    width: 100%;
    height: 40px !important; }
  .spacer-xxl-50 {
    display: block;
    width: 100%;
    height: 50px !important; }
  .spacer-xxl-60 {
    display: block;
    width: 100%;
    height: 60px !important; }
  .spacer-xxl-70 {
    display: block;
    width: 100%;
    height: 70px !important; }
  .spacer-xxl-80 {
    display: block;
    width: 100%;
    height: 80px !important; }
  .spacer-xxl-90 {
    display: block;
    width: 100%;
    height: 90px !important; }
  .spacer-xxl-100 {
    display: block;
    width: 100%;
    height: 100px !important; }
  .spacer-xxl-150 {
    display: block;
    width: 100%;
    height: 150px !important; }
  .spacer-xxl-200 {
    display: block;
    width: 100%;
    height: 200px !important; }
  .spacer-xxl-250 {
    display: block;
    width: 100%;
    height: 250px !important; } }

.border-top {
  border-top: 1px solid #C8CED3 !important; }

.border-right {
  border-right: 1px solid #C8CED3 !important; }

.border-bottom {
  border-bottom: 1px solid #C8CED3 !important; }

.border-left {
  border-left: 1px solid #C8CED3 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-x {
  border-left: 1px solid #C8CED3 !important;
  border-right: 1px solid #C8CED3 !important; }

.border-y {
  border-top: 1px solid #C8CED3 !important;
  border-bottom: 1px solid #C8CED3 !important; }

@media (min-width: 577px) {
  .border-sm-top {
    border-top: 1px solid #C8CED3 !important; }
  .border-sm-right {
    border-right: 1px solid #C8CED3 !important; }
  .border-sm-bottom {
    border-bottom: 1px solid #C8CED3 !important; }
  .border-sm-left {
    border-left: 1px solid #C8CED3 !important; }
  .border-sm-top-0 {
    border-top: 0 !important; }
  .border-sm-right-0 {
    border-right: 0 !important; }
  .border-sm-bottom-0 {
    border-bottom: 0 !important; }
  .border-sm-left-0 {
    border-left: 0 !important; }
  .border-sm-x {
    border-left: 1px solid #C8CED3 !important;
    border-right: 1px solid #C8CED3 !important; }
  .border-sm-y {
    border-top: 1px solid #C8CED3 !important;
    border-bottom: 1px solid #C8CED3 !important; } }

@media (min-width: 769px) {
  .border-md-top {
    border-top: 1px solid #C8CED3 !important; }
  .border-md-right {
    border-right: 1px solid #C8CED3 !important; }
  .border-md-bottom {
    border-bottom: 1px solid #C8CED3 !important; }
  .border-md-left {
    border-left: 1px solid #C8CED3 !important; }
  .border-md-top-0 {
    border-top: 0 !important; }
  .border-md-right-0 {
    border-right: 0 !important; }
  .border-md-bottom-0 {
    border-bottom: 0 !important; }
  .border-md-left-0 {
    border-left: 0 !important; }
  .border-md-x {
    border-left: 1px solid #C8CED3 !important;
    border-right: 1px solid #C8CED3 !important; }
  .border-md-y {
    border-top: 1px solid #C8CED3 !important;
    border-bottom: 1px solid #C8CED3 !important; } }

@media (min-width: 993px) {
  .border-lg-top {
    border-top: 1px solid #C8CED3 !important; }
  .border-lg-right {
    border-right: 1px solid #C8CED3 !important; }
  .border-lg-bottom {
    border-bottom: 1px solid #C8CED3 !important; }
  .border-lg-left {
    border-left: 1px solid #C8CED3 !important; }
  .border-lg-top-0 {
    border-top: 0 !important; }
  .border-lg-right-0 {
    border-right: 0 !important; }
  .border-lg-bottom-0 {
    border-bottom: 0 !important; }
  .border-lg-left-0 {
    border-left: 0 !important; }
  .border-lg-x {
    border-left: 1px solid #C8CED3 !important;
    border-right: 1px solid #C8CED3 !important; }
  .border-lg-y {
    border-top: 1px solid #C8CED3 !important;
    border-bottom: 1px solid #C8CED3 !important; } }

@media (min-width: 1201px) {
  .border-xl-top {
    border-top: 1px solid #C8CED3 !important; }
  .border-xl-right {
    border-right: 1px solid #C8CED3 !important; }
  .border-xl-bottom {
    border-bottom: 1px solid #C8CED3 !important; }
  .border-xl-left {
    border-left: 1px solid #C8CED3 !important; }
  .border-xl-top-0 {
    border-top: 0 !important; }
  .border-xl-right-0 {
    border-right: 0 !important; }
  .border-xl-bottom-0 {
    border-bottom: 0 !important; }
  .border-xl-left-0 {
    border-left: 0 !important; }
  .border-xl-x {
    border-left: 1px solid #C8CED3 !important;
    border-right: 1px solid #C8CED3 !important; }
  .border-xl-y {
    border-top: 1px solid #C8CED3 !important;
    border-bottom: 1px solid #C8CED3 !important; } }

@media (min-width: 1401px) {
  .border-xxl-top {
    border-top: 1px solid #C8CED3 !important; }
  .border-xxl-right {
    border-right: 1px solid #C8CED3 !important; }
  .border-xxl-bottom {
    border-bottom: 1px solid #C8CED3 !important; }
  .border-xxl-left {
    border-left: 1px solid #C8CED3 !important; }
  .border-xxl-top-0 {
    border-top: 0 !important; }
  .border-xxl-right-0 {
    border-right: 0 !important; }
  .border-xxl-bottom-0 {
    border-bottom: 0 !important; }
  .border-xxl-left-0 {
    border-left: 0 !important; }
  .border-xxl-x {
    border-left: 1px solid #C8CED3 !important;
    border-right: 1px solid #C8CED3 !important; }
  .border-xxl-y {
    border-top: 1px solid #C8CED3 !important;
    border-bottom: 1px solid #C8CED3 !important; } }

:root {
  --bs-primary-blue: #0093B2;
  --bs-blue: #4E7AFC;
  --bs-blue-light: #45BFE2;
  --bs-indigo: #6610f2;
  --bs-purple: #8565D5;
  --bs-pink: #d63384;
  --bs-red: #F70000;
  --bs-orange: #FC8667;
  --bs-orange-light: #F8B73B;
  --bs-fire: #FA4616;
  --bs-yellow: #FBE122;
  --bs-green: #3CCE07;
  --bs-green-dark: #25B082;
  --bs-teal: #7EDDD3;
  --bs-cyan: #0dcaf0;
  --bs-beige: #FFF6E7;
  --bs-white: #fff;
  --bs-white-gray: #FAFCFE;
  --bs-light: #F2F5F8;
  --bs-gray-light: #C8CED3;
  --bs-gray: #98A4AE;
  --bs-gray-dark: #525A70;
  --bs-dark: #1C355E;
  --bs-darker: #112341;
  --bs-darkest: #091221;
  --bs-gray-100: #FAFCFE;
  --bs-gray-200: #F2F5F8;
  --bs-gray-300: #C8CED3;
  --bs-gray-400: #98A4AE;
  --bs-gray-500: #525A70;
  --bs-gray-600: #1C355E;
  --bs-gray-700: #112341;
  --bs-gray-800: #091221;
  --bs-primary: #0093B2;
  --bs-secondary: #1C355E;
  --bs-success: #3CCE07;
  --bs-info: #FFF6E7;
  --bs-warning: #FBE122;
  --bs-danger: #F70000;
  --bs-light: #F2F5F8;
  --bs-white: #fff;
  --bs-dark: #1C355E;
  --bs-primary-rgb: 0, 147, 178;
  --bs-secondary-rgb: 28, 53, 94;
  --bs-success-rgb: 60, 206, 7;
  --bs-info-rgb: 255, 246, 231;
  --bs-warning-rgb: 251, 225, 34;
  --bs-danger-rgb: 247, 0, 0;
  --bs-light-rgb: 242, 245, 248;
  --bs-white-rgb: 255, 255, 255;
  --bs-dark-rgb: 28, 53, 94;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 17, 35, 65;
  --bs-body-bg-rgb: 250, 252, 254;
  --bs-font-sans-serif: "Barlow", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #112341;
  --bs-body-bg: #FAFCFE; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1,
.heading-xxxlarge, h2, .h2,
.heading-xxlarge, h3, .h3,
.heading-xlarge, h4, .h4,
.heading-large, h5, .h5,
.heading, h6, .h6,
.heading-small {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1,
.heading-xxxlarge {
  font-size: calc(1.425rem + 2.1vw); }
  @media (min-width: 1200px) {
    h1, .h1,
    .heading-xxxlarge {
      font-size: 3rem; } }

h2, .h2,
.heading-xxlarge {
  font-size: calc(1.35rem + 1.2vw); }
  @media (min-width: 1200px) {
    h2, .h2,
    .heading-xxlarge {
      font-size: 2.25rem; } }

h3, .h3,
.heading-xlarge {
  font-size: calc(1.3125rem + 0.75vw); }
  @media (min-width: 1200px) {
    h3, .h3,
    .heading-xlarge {
      font-size: 1.875rem; } }

h4, .h4,
.heading-large {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4,
    .heading-large {
      font-size: 1.5rem; } }

h5, .h5,
.heading {
  font-size: 1.25rem; }

h6, .h6,
.heading-small {
  font-size: 1.125rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #525A70; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 700; }

small, .small {
  font-size: 0.875rem; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0093B2;
  text-decoration: none;
  transition: all 0.15s ease-in-out; }
  a:hover {
    color: #00768e; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875rem; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875rem;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #091221;
  border-radius: 4px; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 500; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  color: #98A4AE;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.4625rem + 2.55vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 3.375rem; } }

.display-2 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 3rem; } }

.display-3 {
  font-size: calc(1.35rem + 1.2vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 2.25rem; } }

.display-4 {
  font-size: calc(1.3125rem + 0.75vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 1.875rem; } }

.display-5 {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 1.5rem; } }

.display-6 {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.2; }

.xsmall {
  font-size: 0.75rem; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.list-style-numbered {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin: 0;
  padding: 0; }
  .list-style-numbered > li {
    position: relative;
    counter-increment: my-awesome-counter;
    padding-left: 1rem; }
    .list-style-numbered > li:before {
      position: absolute;
      top: 5px;
      left: -1rem;
      content: counter(my-awesome-counter) ".";
      font-weight: 700;
      margin-right: 0.5rem;
      line-height: 1; }

.list-style-roman {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin: 0;
  padding: 0; }
  .list-style-roman > li {
    position: relative;
    counter-increment: my-awesome-counter;
    padding-left: 1rem; }
    .list-style-roman > li:before {
      position: absolute;
      top: 5px;
      left: -1rem;
      content: counter(my-awesome-counter, upper-roman) ".";
      font-weight: 700;
      margin-right: 0.5rem;
      line-height: 1; }

.list-style-check {
  list-style: none;
  margin: 0;
  padding: 0; }
  .list-style-check > li {
    position: relative;
    padding-left: 1rem; }
    .list-style-check > li:before {
      position: absolute;
      top: 8px;
      left: -1rem;
      font-size: .65em;
      content: "✔️";
      margin-right: 0.5rem;
      line-height: 1;
      color: #1C355E; }

.list-style-alpha {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin: 0;
  padding: 0; }
  .list-style-alpha > li {
    position: relative;
    counter-increment: my-awesome-counter;
    padding-left: 1rem; }
    .list-style-alpha > li:before {
      position: absolute;
      top: 5px;
      left: -1rem;
      content: counter(my-awesome-counter, upper-alpha);
      font-weight: 700;
      margin-right: 0.5rem;
      line-height: 1; }
  .list-style-alpha.rounded > li {
    margin-bottom: .5em; }
    .list-style-alpha.rounded > li:before {
      top: 0;
      font-weight: 500;
      background-color: #1C355E;
      color: #fff;
      border-radius: 50%;
      padding: .25em;
      text-align: center;
      width: 1.5em;
      height: 1.5em; }

.initialism {
  font-size: 0.875rem;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #1C355E; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FAFCFE;
  border: 1px solid #C8CED3;
  border-radius: 5px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875rem;
  color: #1C355E; }

.container,
.container-fluid,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 60px;
  padding-left: 60px;
  margin-right: auto;
  margin-left: auto; }
  @media (max-width: 768.98px) {
    .container,
    .container-fluid,
    .container-xl,
    .container-xxl {
      padding-right: 30px;
      padding-left: 30px; } }
  @media (max-width: 576.98px) {
    .container,
    .container-fluid,
    .container-xl,
    .container-xxl {
      padding-right: 15px;
      padding-left: 15px; } }

@media (min-width: 1201px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px; } }

@media (min-width: 1401px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1400px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 577px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 769px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 993px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1201px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1401px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #112341;
  --bs-table-striped-bg: #F2F5F8;
  --bs-table-active-color: #112341;
  --bs-table-active-bg: #F2F5F8;
  --bs-table-hover-color: #112341;
  --bs-table-hover-bg: #F2F5F8;
  width: 100%;
  margin-bottom: 1rem;
  color: #112341;
  vertical-align: top;
  border-color: #C8CED3; }
  .table > :not(caption) > * > * {
    padding: 0.65rem 0.75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
    .table > tbody > tr:last-child > th,
    .table > tbody > tr:last-child > td {
      border-bottom: none; }
  .table > thead {
    vertical-align: bottom; }
    .table > thead > tr > th {
      border: 0; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.4rem 0.625rem; }

.table-sm tr > td,
.table-sm tr > th {
  vertical-align: middle;
  font-size: 0.75rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }
  .table-active:has(+ tr) > td {
    border: none; }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #cce9f0;
  --bs-table-striped-bg: #c2dde4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d2d8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdd8de;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d2d8; }

.table-secondary {
  --bs-table-bg: #d2d7df;
  --bs-table-striped-bg: #c8ccd4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdc2c9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2c7ce;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bdc2c9; }

.table-success {
  --bs-table-bg: #d8f5cd;
  --bs-table-striped-bg: #cde9c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2ddb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8e3be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c2ddb9; }

.table-info {
  --bs-table-bg: #fffdfa;
  --bs-table-striped-bg: #f2f0ee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6e4e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eceae7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6e4e1; }

.table-warning {
  --bs-table-bg: #fef9d3;
  --bs-table-striped-bg: #f1edc8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5e0be;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebe6c3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e5e0be; }

.table-danger {
  --bs-table-bg: #fdcccc;
  --bs-table-striped-bg: #f0c2c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4b8b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eabdbd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e4b8b8; }

.table-light {
  --bs-table-bg: #F2F5F8;
  --bs-table-striped-bg: #e6e9ec;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dadddf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e0e3e5;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dadddf; }

.table-dark {
  --bs-table-bg: #1C355E;
  --bs-table-striped-bg: #273f66;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #33496e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #2d446a;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #33496e; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 576.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 768.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 992.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1200.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1400.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: #1C355E; }

.col-form-label {
  padding-top: calc(0.35rem + 1px);
  padding-bottom: calc(0.35rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 700;
  line-height: 1.5;
  color: #1C355E; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.875rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.75rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #98A4AE; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.35rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  color: #1C355E;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #98A4AE;
  appearance: none;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #1C355E;
    border-color: #0093B2;
    outline: 0; }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #525A70;
    opacity: 1; }
  .form-control:disabled {
    background-color: #F2F5F8;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.35rem 0.75rem;
    margin: -0.35rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #1C355E;
    background-color: #F2F5F8;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #e6e9ec; }
  .form-control::-webkit-file-upload-button {
    padding: 0.35rem 0.75rem;
    margin: -0.35rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #1C355E;
    background-color: #F2F5F8;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #e6e9ec; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.35rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #112341;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: 30px;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 4px; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: 50px;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 5px; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: 36px; }

textarea.form-control-sm {
  min-height: 30px; }

textarea.form-control-lg {
  min-height: 50px; }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.35rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 5px; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 5px; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.35rem 2.25rem 0.35rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  height: 36px;
  color: #0093B2;
  background-color: #e6f4f7;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 120 120'%3e%3cg id='chevron-down' transform='translate%28-37.941 -87.94%29'%3e%3crect id='Rectangle_23' data-name='Rectangle 23' width='120' height='120' transform='translate%2837.94 87.94%29' fill='none'/%3e%3cg id='chevron-right' transform='translate%28-315.088 176.911%29'%3e%3crect id='Rectangle_3' data-name='Rectangle 3' width='106' height='106' transform='translate%28360.029 -81.971%29' fill='none'/%3e%3cpath id='Path_327' data-name='Path 327' d='M10.773,12.773a9.468,9.468,0,0,1,13.39,0L45.871,34.482,67.58,12.773A9.468,9.468,0,1,1,80.969,26.162l-28.4,28.4a9.468,9.468,0,0,1-13.389,0l-28.4-28.4A9.468,9.468,0,0,1,10.773,12.773Z' transform='translate%28367.158 -61.693%29' fill='%230093b2' fill-rule='evenodd'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 20px 18px;
  border: 1px solid #e6f4f7;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  text-transform: uppercase; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #e6f4f7;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(230, 244, 247, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #F2F5F8; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #0093B2; }

.form-select-sm {
  min-height: 30px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.75rem;
  border-radius: 4px; }

.form-select-lg {
  min-height: 50px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 0.875rem;
  border-radius: 5px; }

.form-check {
  display: block;
  min-height: 1rem;
  padding-left: 1.5rem;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5rem; }

.form-check-input {
  width: 1rem;
  height: 1rem;
  margin-top: 0.25rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
  border: 2px solid #98A4AE;
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #0093B2;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(230, 244, 247, 0.25); }
  .form-check-input:checked {
    background-color: #0093B2;
    border-color: #0093B2; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #e6f4f7;
    border-color: #e6f4f7;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%230093B2'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check[disabled] + .btn-outline, .btn-check[disabled] + .btn-outline-secondary, .btn-check:disabled + .btn, .btn-check:disabled + .btn-outline, .btn-check:disabled + .btn-outline-secondary {
    pointer-events: none;
    filter: none;
    opacity: 0.35; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FAFCFE, 0 0 0 0.25rem rgba(230, 244, 247, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FAFCFE, 0 0 0 0.25rem rgba(230, 244, 247, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #e6f4f7;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #f8fcfd; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #C8CED3;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #e6f4f7;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #f8fcfd; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #C8CED3;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #525A70; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #525A70; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    font-size: 0.75rem;
    font-weight: 700;
    color: #1C355E;
    padding: 0.75rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control {
    padding: 0.75rem 0.75rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 1;
    transform: scale(1) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 1;
    transform: scale(1) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn, .input-group .btn-outline, .input-group .btn-outline-secondary {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus, .input-group .btn-outline:focus, .input-group .btn-outline-secondary:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.35rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  color: #1C355E;
  text-align: center;
  white-space: nowrap;
  background-color: #F2F5F8;
  border: 1px solid #98A4AE;
  border-radius: 5px; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.input-group-lg > .btn-outline,
.input-group-lg > .btn-outline-secondary {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 5px; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.input-group-sm > .btn-outline,
.input-group-sm > .btn-outline-secondary {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 4px; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #3CCE07; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(60, 206, 7, 0.9);
  border-radius: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3CCE07;
  padding-right: calc(1.5em + 0.7rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233CCE07' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.175rem) center;
  background-size: calc(0.75em + 0.35rem) calc(0.75em + 0.35rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #3CCE07;
    box-shadow: 0 0 0 0.25rem rgba(60, 206, 7, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.7rem);
  background-position: top calc(0.375em + 0.175rem) right calc(0.375em + 0.175rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #3CCE07; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 120 120'%3e%3cg id='chevron-down' transform='translate%28-37.941 -87.94%29'%3e%3crect id='Rectangle_23' data-name='Rectangle 23' width='120' height='120' transform='translate%2837.94 87.94%29' fill='none'/%3e%3cg id='chevron-right' transform='translate%28-315.088 176.911%29'%3e%3crect id='Rectangle_3' data-name='Rectangle 3' width='106' height='106' transform='translate%28360.029 -81.971%29' fill='none'/%3e%3cpath id='Path_327' data-name='Path 327' d='M10.773,12.773a9.468,9.468,0,0,1,13.39,0L45.871,34.482,67.58,12.773A9.468,9.468,0,1,1,80.969,26.162l-28.4,28.4a9.468,9.468,0,0,1-13.389,0l-28.4-28.4A9.468,9.468,0,0,1,10.773,12.773Z' transform='translate%28367.158 -61.693%29' fill='%230093b2' fill-rule='evenodd'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233CCE07' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 20px 18px, calc(0.75em + 0.35rem) calc(0.75em + 0.35rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #3CCE07;
    box-shadow: 0 0 0 0.25rem rgba(60, 206, 7, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #3CCE07; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #3CCE07; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(60, 206, 7, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #3CCE07; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #F70000; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(247, 0, 0, 0.9);
  border-radius: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F70000;
  padding-right: calc(1.5em + 0.7rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F70000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F70000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.175rem) center;
  background-size: calc(0.75em + 0.35rem) calc(0.75em + 0.35rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #F70000;
    box-shadow: 0 0 0 0.25rem rgba(247, 0, 0, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.7rem);
  background-position: top calc(0.375em + 0.175rem) right calc(0.375em + 0.175rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #F70000; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 120 120'%3e%3cg id='chevron-down' transform='translate%28-37.941 -87.94%29'%3e%3crect id='Rectangle_23' data-name='Rectangle 23' width='120' height='120' transform='translate%2837.94 87.94%29' fill='none'/%3e%3cg id='chevron-right' transform='translate%28-315.088 176.911%29'%3e%3crect id='Rectangle_3' data-name='Rectangle 3' width='106' height='106' transform='translate%28360.029 -81.971%29' fill='none'/%3e%3cpath id='Path_327' data-name='Path 327' d='M10.773,12.773a9.468,9.468,0,0,1,13.39,0L45.871,34.482,67.58,12.773A9.468,9.468,0,1,1,80.969,26.162l-28.4,28.4a9.468,9.468,0,0,1-13.389,0l-28.4-28.4A9.468,9.468,0,0,1,10.773,12.773Z' transform='translate%28367.158 -61.693%29' fill='%230093b2' fill-rule='evenodd'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F70000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F70000' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 20px 18px, calc(0.75em + 0.35rem) calc(0.75em + 0.35rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #F70000;
    box-shadow: 0 0 0 0.25rem rgba(247, 0, 0, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #F70000; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #F70000; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(247, 0, 0, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #F70000; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 12px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 320px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 0 0 25px rgba(9, 18, 33, 0.35);
  box-shadow: 0 0 25px rgba(9, 18, 33, 0.35); }
  .flatpickr-calendar.inline {
    opacity: 1;
    max-height: 640px;
    visibility: visible; }
  .flatpickr-calendar.open {
    opacity: 1;
    max-height: 640px;
    visibility: visible;
    display: inline-block;
    z-index: 99999; }
  .flatpickr-calendar.animate.open {
    -webkit-animation: none;
    animation: none; }
  .flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px; }
  .flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px); }
    .flatpickr-calendar.static.open {
      z-index: 999;
      display: block; }
  .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n + 1) .flatpickr-day.inRange:nth-child(7n + 7) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n + 2) .flatpickr-day.inRange:nth-child(7n + 1) {
    -webkit-box-shadow: -2px 0 0 #F2F5F8, 5px 0 0 #F2F5F8;
    box-shadow: -2px 0 0 #F2F5F8, 5px 0 0 #F2F5F8; }
  .flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .flatpickr-calendar .hasWeeks .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0; }
  .flatpickr-calendar.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid #C8CED3; }
  .flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto; }
  .flatpickr-calendar:after, .flatpickr-calendar:before {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    left: 22px; }
  .flatpickr-calendar.rightMost:after, .flatpickr-calendar.rightMost:before {
    left: auto;
    right: 22px; }
  .flatpickr-calendar:before {
    border-width: 5px;
    margin: 0 -5px; }
  .flatpickr-calendar:after {
    border-width: 4px;
    margin: 0 -4px; }
  .flatpickr-calendar.arrowTop:after {
    bottom: 100%; }
  .flatpickr-calendar.arrowTop:before {
    bottom: 100%;
    border-bottom-color: #C8CED3; }
  .flatpickr-calendar.arrowTop:after {
    border-bottom-color: #fff; }
  .flatpickr-calendar.arrowBottom:after {
    top: 100%; }
  .flatpickr-calendar.arrowBottom:before {
    top: 100%;
    border-top-color: #C8CED3; }
  .flatpickr-calendar.arrowBottom:after {
    border-top-color: #fff; }
  .flatpickr-calendar:focus {
    outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .flatpickr-months .flatpickr-month {
    background: transparent;
    color: rgba(0, 0, 0, 0.9);
    fill: rgba(0, 0, 0, 0.9);
    height: 50px;
    line-height: 1;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1; }
  .flatpickr-months .flatpickr-next-month,
  .flatpickr-months .flatpickr-prev-month {
    position: relative;
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    padding: 2px;
    width: 50px;
    height: 50px;
    z-index: 3;
    color: rgba(0, 0, 0, 0.9);
    fill: rgba(0, 0, 0, 0.9); }
    .flatpickr-months .flatpickr-next-month > svg,
    .flatpickr-months .flatpickr-prev-month > svg {
      display: none; }
    .flatpickr-months .flatpickr-next-month:before,
    .flatpickr-months .flatpickr-prev-month:before {
      font-family: 'peak-icons' !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1rem;
      color: #525A70;
      background-color: #F2F5F8;
      width: 35px;
      height: 35px;
      line-height: 35px;
      border-radius: 2px; }
  .flatpickr-months .flatpickr-prev-month:before {
    content: "\e916"; }
  .flatpickr-months .flatpickr-next-month:before {
    content: "\e910"; }
  .flatpickr-months .flatpickr-next-month.flatpickr-disabled,
  .flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
    display: none; }
  .flatpickr-months .flatpickr-next-month i,
  .flatpickr-months .flatpickr-prev-month i {
    position: relative; }
  .flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
  .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
    /*
      /*rtl:begin:ignore*/
    left: 0;
    /*
      /*rtl:end:ignore*/ }
  .flatpickr-months .flatpickr-next-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
    /*
      /*rtl:begin:ignore*/
    right: 0;
    /*
      /*rtl:end:ignore*/ }
  .flatpickr-months .flatpickr-next-month:hover,
  .flatpickr-months .flatpickr-prev-month:hover {
    color: #0093B2; }
  .flatpickr-months .flatpickr-next-month:hover svg,
  .flatpickr-months .flatpickr-prev-month:hover svg {
    fill: #f64747; }
  .flatpickr-months .flatpickr-next-month svg,
  .flatpickr-months .flatpickr-prev-month svg {
    width: 14px;
    height: 14px; }
  .flatpickr-months .flatpickr-next-month svg path,
  .flatpickr-months .flatpickr-prev-month svg path {
    -webkit-transition: fill 0.1s;
    transition: fill 0.1s;
    fill: inherit; }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.numInputWrapper {
  position: relative;
  height: auto; }
  .numInputWrapper input,
  .numInputWrapper span {
    display: inline-block; }
  .numInputWrapper input {
    width: 100%; }
    .numInputWrapper input::-ms-clear {
      display: none; }
    .numInputWrapper input::-webkit-inner-spin-button, .numInputWrapper input::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none; }
  .numInputWrapper span {
    position: absolute;
    right: 0;
    width: 25px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    border: 1px solid rgba(57, 57, 57, 0.15);
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    .numInputWrapper span:hover {
      background: #F2F5F8; }
    .numInputWrapper span:active {
      background: #F2F5F8; }
    .numInputWrapper span:after {
      display: block;
      content: "";
      position: absolute; }
    .numInputWrapper span.arrowUp {
      top: 0;
      border-bottom: 0; }
      .numInputWrapper span.arrowUp:after {
        content: "\e911";
        font-family: 'peak-icons' !important;
        font-size: .85rem;
        color: #000;
        text-align: center;
        top: 26%;
        left: 50%;
        transform: translateX(-50%); }
    .numInputWrapper span.arrowDown {
      top: 50%; }
      .numInputWrapper span.arrowDown:after {
        content: "\e917";
        font-family: 'peak-icons' !important;
        font-size: .85rem;
        color: #000;
        text-align: center;
        top: 38%;
        left: 50%;
        transform: translateX(-50%); }
    .numInputWrapper span svg {
      width: inherit;
      height: auto; }
      .numInputWrapper span svg path {
        fill: rgba(0, 0, 0, 0.5); }
  .numInputWrapper:hover {
    background: #FAFCFE; }
    .numInputWrapper:hover span {
      opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  line-height: 1;
  height: 50px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  margin-top: 5px; }
  .flatpickr-current-month span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0; }
    .flatpickr-current-month span.cur-month:hover {
      background: rgba(0, 0, 0, 0.05); }
  .flatpickr-current-month .numInputWrapper {
    width: 8ch;
    width: 7ch\0;
    display: inline-block; }
    .flatpickr-current-month .numInputWrapper span.arrowUp:after {
      border-bottom-color: #C8CED3; }
    .flatpickr-current-month .numInputWrapper span.arrowDown:after {
      border-top-color: #C8CED3; }
  .flatpickr-current-month input.cur-year {
    background: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    padding: 0 0 0 0.5ch;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    font-family: inherit;
    font-weight: 500;
    line-height: inherit;
    height: auto;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    height: 40px; }
    .flatpickr-current-month input.cur-year:focus {
      outline: 0; }
    .flatpickr-current-month input.cur-year[disabled] {
      font-size: 100%;
      color: rgba(0, 0, 0, 0.5);
      background: transparent;
      pointer-events: none; }
      .flatpickr-current-month input.cur-year[disabled]:hover {
        font-size: 100%;
        color: rgba(0, 0, 0, 0.5);
        background: transparent;
        pointer-events: none; }
  .flatpickr-current-month .flatpickr-monthDropdown-months {
    appearance: menulist;
    background: transparent;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    height: 40px;
    line-height: inherit;
    margin: -1px 0 0;
    outline: none;
    padding: 0 0 0 0.5ch;
    position: relative;
    vertical-align: initial;
    -webkit-box-sizing: border-box;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    width: auto;
    font-weight: 500; }
    .flatpickr-current-month .flatpickr-monthDropdown-months:active, .flatpickr-current-month .flatpickr-monthDropdown-months:focus {
      outline: none; }
    .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
      background: #FAFCFE; }
    .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
      background-color: transparent;
      outline: none;
      padding: 0; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }
  .flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: #525A70;
  text-transform: uppercase;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: 400; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px; }
  .flatpickr-days:focus {
    outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }
  .dayContainer + .dayContainer {
    -webkit-box-shadow: -1px 0 0 #F2F5F8;
    box-shadow: -1px 0 0 #F2F5F8; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-weight: 500;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 36px;
  height: 36px;
  line-height: 36px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }
  .flatpickr-day.inRange {
    cursor: pointer;
    outline: 0;
    background: #F2F5F8;
    border-color: #F2F5F8; }
  .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day.nextMonthDay:focus, .flatpickr-day.nextMonthDay:hover {
    cursor: pointer;
    outline: 0;
    background: #F2F5F8;
    border-color: #F2F5F8; }
  .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.prevMonthDay:hover {
    cursor: pointer;
    outline: 0;
    background: #F2F5F8;
    border-color: #F2F5F8; }
  .flatpickr-day.today.inRange, .flatpickr-day:focus, .flatpickr-day:hover {
    cursor: pointer;
    outline: 0;
    background: #F2F5F8;
    border-color: #F2F5F8; }
  .flatpickr-day.today {
    border-color: #0093B2; }
    .flatpickr-day.today:focus, .flatpickr-day.today:hover {
      border-color: #0093B2;
      background: #0093B2;
      color: #fff; }
  .flatpickr-day.endRange {
    background: #569ff7;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7; }
    .flatpickr-day.endRange.inRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover {
      background: #569ff7;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #fff;
      border-color: #569ff7; }
  .flatpickr-day.selected {
    background: #0093B2;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #0093B2; }
    .flatpickr-day.selected.inRange, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover {
      background: #0093B2;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #fff;
      border-color: #0093B2; }
  .flatpickr-day.startRange {
    background: #569ff7;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7; }
    .flatpickr-day.startRange.inRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
      background: #569ff7;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #fff;
      border-color: #569ff7; }
  .flatpickr-day.endRange.startRange, .flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange {
    border-radius: 50px 0 0 50px; }
  .flatpickr-day.endRange.endRange, .flatpickr-day.selected.endRange {
    border-radius: 0 50px 50px 0; }
  .flatpickr-day.startRange.endRange {
    border-radius: 0 50px 50px 0; }
    .flatpickr-day.startRange.endRange + .endRange:not(:nth-child(7n + 1)) {
      -webkit-box-shadow: -10px 0 0 #569ff7;
      box-shadow: -10px 0 0 #569ff7; }
  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    -webkit-box-shadow: -10px 0 0 #569ff7;
    box-shadow: -10px 0 0 #569ff7; }
  .flatpickr-day.endRange.startRange.endRange, .flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange {
    border-radius: 50px; }
  .flatpickr-day.inRange {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #F2F5F8, 5px 0 0 #F2F5F8;
    box-shadow: -5px 0 0 #F2F5F8, 5px 0 0 #F2F5F8; }
  .flatpickr-day.flatpickr-disabled {
    color: rgba(57, 57, 57, 0.3);
    background: transparent;
    border-color: transparent;
    cursor: default; }
    .flatpickr-day.flatpickr-disabled:hover {
      color: rgba(57, 57, 57, 0.3);
      background: transparent;
      border-color: transparent;
      cursor: default; }
  .flatpickr-day.nextMonthDay {
    color: rgba(57, 57, 57, 0.3);
    background: transparent;
    border-color: transparent;
    cursor: default; }
  .flatpickr-day.notAllowed {
    color: rgba(57, 57, 57, 0.3);
    background: transparent;
    border-color: transparent;
    cursor: default; }
    .flatpickr-day.notAllowed.nextMonthDay, .flatpickr-day.notAllowed.prevMonthDay {
      color: rgba(57, 57, 57, 0.3);
      background: transparent;
      border-color: transparent;
      cursor: default; }
  .flatpickr-day.prevMonthDay {
    color: rgba(57, 57, 57, 0.3);
    background: transparent;
    border-color: transparent;
    cursor: default; }
  .flatpickr-day.flatpickr-disabled {
    cursor: not-allowed;
    color: rgba(57, 57, 57, 0.1); }
    .flatpickr-day.flatpickr-disabled:hover {
      cursor: not-allowed;
      color: rgba(57, 57, 57, 0.1); }
  .flatpickr-day.week.selected {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
    box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; }
  .flatpickr-day.hidden {
    visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  float: left; }
  .flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    -webkit-box-shadow: 1px 0 0 #F2F5F8;
    box-shadow: 1px 0 0 #F2F5F8; }
  .flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px; }
  .flatpickr-weekwrapper span.flatpickr-day {
    display: block;
    width: 100%;
    max-width: none;
    color: rgba(57, 57, 57, 0.3);
    background: transparent;
    cursor: default;
    border: none; }
    .flatpickr-weekwrapper span.flatpickr-day:hover {
      display: block;
      width: 100%;
      max-width: none;
      color: rgba(57, 57, 57, 0.3);
      background: transparent;
      cursor: default;
      border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 40px;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .flatpickr-time:after {
    content: "";
    display: table;
    clear: both; }
  .flatpickr-time .numInputWrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 40%;
    height: 40px;
    float: left; }
    .flatpickr-time .numInputWrapper span.arrowUp:after {
      border-bottom-color: #C8CED3; }
    .flatpickr-time .numInputWrapper span.arrowDown:after {
      border-top-color: #C8CED3; }
  .flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%; }
  .flatpickr-time.time24hr .numInputWrapper {
    width: 49%; }
  .flatpickr-time input {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    color: #393939;
    font-size: 14px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield; }
    .flatpickr-time input.flatpickr-hour {
      font-weight: 500; }
    .flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
      font-weight: 500; }
    .flatpickr-time input:focus {
      outline: 0;
      border: 0; }
  .flatpickr-time .flatpickr-am-pm,
  .flatpickr-time .flatpickr-time-separator {
    height: inherit;
    float: left;
    line-height: inherit;
    color: #393939;
    font-weight: bold;
    width: 2%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center; }
  .flatpickr-time .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 500; }
    .flatpickr-time .flatpickr-am-pm:focus, .flatpickr-time .flatpickr-am-pm:hover {
      background: #fff; }
  .flatpickr-time input:focus, .flatpickr-time input:hover {
    background: #fff; }

.flatpickr-input[readonly] {
  cursor: pointer; }
  .flatpickr-input[readonly]:focus {
    outline: none;
    box-shadow: none; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.btn, .btn-outline, .btn-outline-secondary {
  display: inline-block;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: #0093B2;
  min-height: 36px;
  max-height: 36px;
  border: 2px solid transparent;
  padding: 0.35rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .btn-outline, .btn-outline-secondary {
      transition: none; } }
  .btn:hover, .btn-outline:hover, .btn-outline-secondary:hover {
    color: #fff;
    background-color: #66bed1; }
  .btn-check:focus + .btn, .btn-check:focus + .btn-outline, .btn-check:focus + .btn-outline-secondary, .btn:focus, .btn-outline:focus, .btn-outline-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(230, 244, 247, 0.25); }
  .btn:disabled, .btn-outline:disabled, .btn-outline-secondary:disabled, .btn.disabled, .disabled.btn-outline, .disabled.btn-outline-secondary,
  fieldset:disabled .btn,
  fieldset:disabled .btn-outline,
  fieldset:disabled .btn-outline-secondary {
    pointer-events: none;
    opacity: 0.35; }
  .btn.btn-tight, .btn-tight.btn-outline, .btn-tight.btn-outline-secondary {
    padding: 0.35rem calc(1.5rem / 1.5); }

.btn-outline {
  background-color: #fff;
  border: 2px solid #99d4e0;
  color: #0093B2; }
  .btn-outline:hover {
    color: #0093B2;
    background-color: #e6f4f7;
    border-color: #66bed1; }

.btn-outline-secondary {
  background-color: #fff;
  border: 2px solid #99d4e0;
  color: #0093B2; }
  .btn-outline-secondary:hover {
    color: #66bed1;
    background-color: transparent;
    border-color: #99d4e0; }

.btn-link {
  font-weight: 700;
  color: #0093B2;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  white-space: nowrap; }
  .btn-link:hover {
    color: #00768e; }
  .btn-link:disabled, .btn-link.disabled {
    color: #1C355E; }

.btn-link-sm {
  font-size: 0.75rem; }

.btn-link-lg {
  font-size: 0.875rem; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .btn-outline, .btn-group-lg > .btn-outline-secondary {
  padding: 0.75rem 1.875rem;
  font-size: 0.875rem;
  border-radius: 5px;
  min-height: 50px;
  max-height: 50px; }

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .btn-outline, .btn-group-sm > .btn-outline-secondary {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 4px;
  min-height: 30px;
  max-height: 30px; }

.title-link {
  color: #1C355E; }
  .title-link:hover, .title-link:focus {
    color: #0093B2; }
    .title-link:hover > .i-circle-rounded, .title-link:focus > .i-circle-rounded {
      color: #fff;
      background-color: #0093B2; }

.close-btn {
  cursor: pointer; }
  .close-btn:hover > i {
    color: #fff;
    background-color: #0093B2; }

.btn-ttmadvisor {
  position: fixed;
  z-index: 888;
  bottom: 60px;
  right: 30px; }
  @media (max-width: 768.98px) {
    .btn-ttmadvisor {
      display: none; } }

.cursor-pointer {
  cursor: pointer; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #112341;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px; }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }
  .dropdown-menu.dropdown-menu-secondary > li .dropdown-item {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700;
    color: #525A70;
    border-bottom: 1px solid #C8CED3;
    padding: calc(0.25rem * 2) 1rem; }
    .dropdown-menu.dropdown-menu-secondary > li .dropdown-item:hover, .dropdown-menu.dropdown-menu-secondary > li .dropdown-item:focus {
      color: #1C355E;
      background-color: #e6f4f7; }
  .dropdown-menu.dropdown-menu-secondary > li:last-child .dropdown-item {
    border-bottom: 0; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 577px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 769px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 993px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1201px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1401px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #091221;
  text-align: inherit;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1C355E;
    background-color: #e6f4f7; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #e6f4f7; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #525A70;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #1C355E;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #091221; }

.dropdown-menu-dark {
  color: #C8CED3;
  background-color: #091221;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #C8CED3; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #e6f4f7; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #525A70; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #C8CED3; }
  .dropdown-menu-dark .dropdown-header {
    color: #525A70; }

.dropdown-sticky-header {
  position: fixed;
  z-index: 888;
  top: 60px;
  left: 0;
  width: 100%; }
  @media (max-width: 768.98px) {
    .dropdown-sticky-header {
      top: 50px; } }
  .dropdown-sticky-header .dropdown-toggle {
    width: 100%;
    height: 50px;
    background-color: #fff;
    border-bottom: 1px solid #C8CED3;
    padding: .5rem 3.5rem .5rem 1rem;
    display: flex;
    align-items: center;
    font-weight: 700;
    position: relative; }
    .dropdown-sticky-header .dropdown-toggle > span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .dropdown-sticky-header .dropdown-toggle:after {
      content: "\e917";
      position: absolute;
      right: 1rem;
      font-family: 'peak-icons';
      font-size: 1.125rem;
      transition: transform .2s ease-in-out;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      line-height: 30px;
      text-align: center;
      color: #0093B2;
      background-color: #e6f4f7; }
    .dropdown-sticky-header .dropdown-toggle[aria-expanded="true"]:after {
      transform: rotate(180deg);
      color: #fff;
      background-color: #0093B2; }
  .dropdown-sticky-header .dropdown-menu {
    width: 100%;
    border-radius: 0; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .btn-group > .btn-outline, .btn-group > .btn-outline-secondary,
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-outline,
  .btn-group-vertical > .btn-outline-secondary {
    position: relative;
    flex: 1 1 auto;
    padding: 0.375rem .75rem; }
  .btn-group > .btn-check + .btn, .btn-group > .btn-check + .btn-outline, .btn-group > .btn-check + .btn-outline-secondary,
  .btn-group-vertical > .btn-check + .btn,
  .btn-group-vertical > .btn-check + .btn-outline,
  .btn-group-vertical > .btn-check + .btn-outline-secondary {
    background-color: #fff;
    color: #66bed1;
    border-color: #99d4e0; }
  .btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:checked + .btn-outline, .btn-group > .btn-check:checked + .btn-outline-secondary,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn-check:focus + .btn-outline,
  .btn-group > .btn-check:focus + .btn-outline-secondary,
  .btn-group > .btn:hover,
  .btn-group > .btn-outline:hover,
  .btn-group > .btn-outline-secondary:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn-outline:focus,
  .btn-group > .btn-outline-secondary:focus,
  .btn-group > .btn:active,
  .btn-group > .btn-outline:active,
  .btn-group > .btn-outline-secondary:active,
  .btn-group > .btn.active,
  .btn-group > .active.btn-outline,
  .btn-group > .active.btn-outline-secondary,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:checked + .btn-outline,
  .btn-group-vertical > .btn-check:checked + .btn-outline-secondary,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn-check:focus + .btn-outline,
  .btn-group-vertical > .btn-check:focus + .btn-outline-secondary,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn-outline:hover,
  .btn-group-vertical > .btn-outline-secondary:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn-outline:focus,
  .btn-group-vertical > .btn-outline-secondary:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn-outline:active,
  .btn-group-vertical > .btn-outline-secondary:active,
  .btn-group-vertical > .btn.active,
  .btn-group-vertical > .active.btn-outline,
  .btn-group-vertical > .active.btn-outline-secondary {
    z-index: 1;
    background-color: #e6f4f7;
    color: #0093B2; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .btn-group > .btn-outline:not(:first-child), .btn-group > .btn-outline-secondary:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-outline:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-outline-secondary:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .btn-outline,
.btn-group > .btn-group:not(:last-child) > .btn-outline-secondary {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3), .btn-group > .btn-outline:nth-child(n + 3), .btn-group > .btn-outline-secondary:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > :not(.btn-check) + .btn-outline,
.btn-group > :not(.btn-check) + .btn-outline-secondary,
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .btn-outline,
.btn-group > .btn-group:not(:first-child) > .btn-outline-secondary {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .btn-outline + .dropdown-toggle-split, .btn-group-sm > .btn-outline-secondary + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .btn-outline + .dropdown-toggle-split, .btn-group-lg > .btn-outline-secondary + .dropdown-toggle-split {
  padding-right: 1.40625rem;
  padding-left: 1.40625rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-outline,
  .btn-group-vertical > .btn-outline-secondary,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-outline:not(:first-child), .btn-group-vertical > .btn-outline-secondary:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -2px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-outline:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-outline-secondary:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .btn-group:not(:last-child) > .btn-outline,
  .btn-group-vertical > .btn-group:not(:last-child) > .btn-outline-secondary {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-outline ~ .btn, .btn-group-vertical > .btn-outline-secondary ~ .btn, .btn-group-vertical > .btn ~ .btn-outline, .btn-group-vertical > .btn-outline ~ .btn-outline, .btn-group-vertical > .btn-outline-secondary ~ .btn-outline, .btn-group-vertical > .btn ~ .btn-outline-secondary, .btn-group-vertical > .btn-outline ~ .btn-outline-secondary, .btn-group-vertical > .btn-outline-secondary ~ .btn-outline-secondary,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn-outline,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn-outline-secondary {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-header {
  position: fixed;
  z-index: 999;
  background-color: #1C355E;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center; }
  @media (max-width: 768.98px) {
    .nav-header {
      height: 50px; } }
  .nav-header .nav-header-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 30px; }
    @media (max-width: 768.98px) {
      .nav-header .nav-header-inner {
        padding-right: 15px;
        padding-left: 0; } }
    @media (max-width: 768.98px) {
      .nav-header .nav-header-inner .nav-header-start .nav-header-brand {
        margin-left: 65px; } }
    .nav-header .nav-header-inner .nav-header-start .nav-header-brand > a > img {
      width: 115px; }
      @media (max-width: 768.98px) {
        .nav-header .nav-header-inner .nav-header-start .nav-header-brand > a > img {
          width: 100px; } }
    .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger {
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 15px calc(15px * 0.834);
      display: flex;
      align-items: center;
      cursor: pointer;
      transition-property: opacity, background-color;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      font: inherit;
      color: inherit;
      text-transform: none;
      background-color: #0093B2;
      border: 0;
      margin: 0;
      overflow: visible; }
      @media (min-width: 769px) {
        .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger {
          display: none; } }
      .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger .hamburger-box {
        width: 25px;
        height: 16px;
        display: inline-block;
        position: relative; }
      .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: -1px;
        transition-duration: 0.15s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
        .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger .hamburger-inner, .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger .hamburger-inner::before, .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger .hamburger-inner::after {
          width: 25px;
          height: 2px;
          background-color: #fff;
          border-radius: 5px;
          position: absolute;
          left: 0;
          transition-property: transform;
          transition-duration: 0.1s;
          transition-timing-function: ease; }
        .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger .hamburger-inner::after {
          width: 8px; }
        .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger .hamburger-inner {
          width: 20px; }
        .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger .hamburger-inner::before, .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger .hamburger-inner::after {
          content: "";
          display: block; }
        .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger .hamburger-inner::before {
          top: -7px;
          transition: top 0.15s 0.12s ease, opacity 0.175s ease; }
        .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger .hamburger-inner::after {
          bottom: -7px;
          transition: bottom 0.15s 0.12s ease, transform 0.175s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
      .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger.active {
        background-color: #0093B2; }
        .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger.active .hamburger-inner {
          transform: rotate(45deg);
          transition-delay: 0.12s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
          .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger.active .hamburger-inner {
            width: 25px; }
          .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger.active .hamburger-inner:before {
            top: 0;
            opacity: 0;
            transition: top 0.15s ease, opacity 0.15s 0.12s ease; }
          .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger.active .hamburger-inner::after {
            width: 25px;
            bottom: 0;
            transform: rotate(-90deg);
            transition: bottom 0.15s ease, transform 0.15s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }
        .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger.active .hamburger-inner,
        .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger.active .hamburger-inner::before,
        .nav-header .nav-header-inner .nav-header-start .nav-header-hamburger.active .hamburger-inner::after {
          background-color: #fff; }
    .nav-header .nav-header-inner .nav-header-end > ul {
      list-style-type: none;
      display: flex;
      margin: 0; }
      .nav-header .nav-header-inner .nav-header-end > ul > li > a {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 500;
        color: #C8CED3;
        padding-left: 1.25em;
        padding-right: 1.25em; }
        .nav-header .nav-header-inner .nav-header-end > ul > li > a:hover {
          color: #fff; }
        .nav-header .nav-header-inner .nav-header-end > ul > li > a:after {
          content: '';
          position: absolute;
          right: 0;
          height: 30px;
          width: 1px;
          background-color: rgba(255, 255, 255, 0.25); }
          @media (max-width: 768.98px) {
            .nav-header .nav-header-inner .nav-header-end > ul > li > a:after {
              content: none; } }
        .nav-header .nav-header-inner .nav-header-end > ul > li > a > i {
          font-size: 1.875em;
          vertical-align: middle;
          margin-right: 3px; }
      .nav-header .nav-header-inner .nav-header-end > ul > li:last-child > a {
        padding-right: 0; }
        .nav-header .nav-header-inner .nav-header-end > ul > li:last-child > a:after {
          content: none; }
      @media (max-width: 768.98px) {
        .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification {
          order: 3; }
          .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification > a {
            padding-right: 0; }
            .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification > a > span {
              display: none; } }
      .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu {
        width: 320px !important;
        border-radius: 0;
        padding: 0;
        box-shadow: 0 1rem 3rem rgba(9, 18, 33, 0.175); }
        .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-header {
          position: fixed;
          width: 100%;
          top: 0;
          left: 0;
          height: 40px;
          border-bottom: 1px solid #C8CED3;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.25rem 1rem; }
          .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-header > span:first-child {
            font-size: 0.875rem;
            font-weight: 700; }
          .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-header > span:last-child {
            display: block;
            cursor: pointer;
            padding: 3px 10px;
            color: #0093B2;
            text-transform: uppercase;
            font-size: 0.75rem;
            font-weight: 700;
            border-radius: 5px; }
            .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-header > span:last-child:hover {
              background-color: #e6f4f7; }
        .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-wrapper {
          margin-top: 40px;
          padding: 5px 0 10px;
          max-height: 450px;
          overflow-y: auto; }
          .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-wrapper > ul {
            list-style-type: none;
            padding: 0;
            margin: 0; }
            .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-wrapper > ul > li.nav-header-notification-menu-item {
              padding: 2px 5px; }
              .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-wrapper > ul > li.nav-header-notification-menu-item > a.dropdown-item {
                padding: 10px 15px; }
                .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-wrapper > ul > li.nav-header-notification-menu-item > a.dropdown-item > div {
                  color: #525A70;
                  white-space: break-spaces;
                  font-size: 0.875rem;
                  line-height: 1.125;
                  font-weight: 500; }
                  .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-wrapper > ul > li.nav-header-notification-menu-item > a.dropdown-item > div > span {
                    color: #0093B2;
                    font-weight: 700; }
                .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-wrapper > ul > li.nav-header-notification-menu-item > a.dropdown-item > small, .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-wrapper > ul > li.nav-header-notification-menu-item > a.dropdown-item > .small {
                  margin-top: 3px;
                  display: block;
                  line-height: 1;
                  font-size: 0.75rem;
                  color: #1C355E; }
              .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-wrapper > ul > li.nav-header-notification-menu-item.new > a.dropdown-item {
                color: #1C355E;
                background-color: #e6f4f7; }
                .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification .nav-header-notification-dropdown-menu > li.nav-header-notification-menu-wrapper > ul > li.nav-header-notification-menu-item.new > a.dropdown-item:hover {
                  background-color: #d9eff3; }
      .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification.active > a {
        color: #fff; }
        .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification.active > a > i {
          position: relative; }
          .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-notification.active > a > i:after {
            content: '';
            position: absolute;
            top: -7px;
            right: -5px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: 3px solid #1C355E;
            background-color: #FA4616; }
      @media (max-width: 768.98px) {
        .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-logout, .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-faq {
          display: none; } }
      .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-faq > a, .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-lang > a {
        padding-top: .125rem; }
        @media (max-width: 768.98px) {
          .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-faq > a, .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-lang > a {
            padding-top: 0; } }
      .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-chat {
        display: none; }
        .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-chat > a > i {
          color: #fff; }
        @media (max-width: 768.98px) {
          .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-chat {
            display: block;
            order: 2; } }
        @media only screen and (max-width: 375px) {
          .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-chat {
            display: none; } }
      @media (max-width: 768.98px) {
        .nav-header .nav-header-inner .nav-header-end > ul > li.nav-header-lang > a {
          font-size: 0.875rem; } }

.nav-aside {
  width: 100px;
  background-color: #112341;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 60px;
  bottom: 0;
  height: calc(100vh - 60px);
  padding-top: 20px;
  padding-bottom: 30px; }
  @media (max-width: 768.98px) {
    .nav-aside {
      display: none; } }
  .nav-aside > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .nav-aside > ul > li {
      margin-bottom: 20px; }
      .nav-aside > ul > li > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 5px; }
        .nav-aside > ul > li > a > i {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background-color: #525A70;
          color: #fff;
          text-align: center;
          line-height: 34px;
          font-size: 1.35em;
          transition: all 0.15s ease-in-out; }
        .nav-aside > ul > li > a > span {
          display: block;
          margin-top: 5px;
          text-transform: uppercase;
          color: #F2F5F8;
          font-size: 0.688rem;
          font-weight: 500;
          transition: all 0.15s ease-in-out;
          white-space: initial;
          text-align: center; }
        .nav-aside > ul > li > a:hover > i, .nav-aside > ul > li > a:focus > i, .nav-aside > ul > li > a.active > i {
          background-color: #0093B2; }
        .nav-aside > ul > li > a:hover > span, .nav-aside > ul > li > a:focus > span, .nav-aside > ul > li > a.active > span {
          color: #fff; }
      .nav-aside > ul > li:last-child {
        margin-top: auto;
        margin-bottom: 0; }
      .nav-aside > ul > li .dropdown-menu {
        left: -3px !important;
        width: 320px;
        background-color: #1C355E;
        padding: 0;
        border-radius: 0; }
        .nav-aside > ul > li .dropdown-menu > li > a {
          display: block;
          color: #fff;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 0.75rem;
          padding: .5rem 20px;
          min-height: 36px; }
          .nav-aside > ul > li .dropdown-menu > li > a:hover, .nav-aside > ul > li .dropdown-menu > li > a:focus {
            background-color: #112341; }
        .nav-aside > ul > li .dropdown-menu > li:not(:last-child) > a {
          border-bottom: 1px solid rgba(255, 255, 255, 0.25); }

.nav-mobile {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 50px;
  bottom: 0;
  height: calc(100vh - 50px);
  padding-bottom: 30px;
  background-color: #112341;
  width: 100%;
  max-width: 420px;
  overflow-y: auto;
  transform: translateX(-420px);
  transition: transform 0.15s cubic-bezier(0.83, 0, 0.17, 1); }
  .nav-mobile.show {
    transform: translateX(0); }
  .nav-mobile .nav-mobile-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .nav-mobile .nav-mobile-menu > li > a {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 15px;
      border-top: 1px solid #1C355E; }
      .nav-mobile .nav-mobile-menu > li > a > i {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #525A70;
        color: #fff;
        text-align: center;
        line-height: 30px;
        font-size: 1.125em;
        transition: all 0.15s ease-in-out; }
      .nav-mobile .nav-mobile-menu > li > a > span {
        display: block;
        margin-left: 20px;
        text-transform: uppercase;
        color: #F2F5F8;
        font-size: 0.875rem;
        font-weight: 500;
        transition: all 0.15s ease-in-out; }
      .nav-mobile .nav-mobile-menu > li > a:hover {
        background-color: #1C355E; }
      .nav-mobile .nav-mobile-menu > li > a:hover > i, .nav-mobile .nav-mobile-menu > li > a:focus > i, .nav-mobile .nav-mobile-menu > li > a.active > i {
        background-color: #0093B2; }
      .nav-mobile .nav-mobile-menu > li > a:hover > span, .nav-mobile .nav-mobile-menu > li > a:focus > span, .nav-mobile .nav-mobile-menu > li > a.active > span {
        color: #fff; }
    .nav-mobile .nav-mobile-menu > li:first-child > a {
      border-top: 0; }
    .nav-mobile .nav-mobile-menu > li.nav-mobile-menu-dropdown {
      transition: background-color .2s ease-in-out; }
      .nav-mobile .nav-mobile-menu > li.nav-mobile-menu-dropdown > a {
        position: relative; }
        .nav-mobile .nav-mobile-menu > li.nav-mobile-menu-dropdown > a:after {
          content: "\e917";
          position: absolute;
          right: 30px;
          font-family: 'peak-icons';
          font-size: 1.125rem;
          transition: transform .2s ease-in-out;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          line-height: 30px;
          text-align: center; }
        .nav-mobile .nav-mobile-menu > li.nav-mobile-menu-dropdown > a[aria-expanded="true"] {
          background-color: #1C355E; }
          .nav-mobile .nav-mobile-menu > li.nav-mobile-menu-dropdown > a[aria-expanded="true"]:after {
            transform: rotate(180deg);
            color: #fff;
            background-color: #0093B2; }
      .nav-mobile .nav-mobile-menu > li.nav-mobile-menu-dropdown .nav-mobile-menu-collpase {
        list-style-type: none;
        padding: 0;
        margin: 0; }
        .nav-mobile .nav-mobile-menu > li.nav-mobile-menu-dropdown .nav-mobile-menu-collpase > li > a {
          display: block;
          color: #fff;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 0.75rem;
          padding: .5rem 20px;
          min-height: 36px;
          background-color: #1C355E; }
        .nav-mobile .nav-mobile-menu > li.nav-mobile-menu-dropdown .nav-mobile-menu-collpase > li:not(:last-child) > a {
          border-bottom: 1px solid rgba(255, 255, 255, 0.25); }
  .nav-mobile .nav-mobile-footer {
    list-style-type: none;
    margin: 30px 0 0 0;
    padding: 0; }
    .nav-mobile .nav-mobile-footer > li > a {
      display: block;
      font-size: 0.875rem;
      color: #C8CED3;
      padding: 8px 15px; }

.subpage-nav-aside {
  overflow: hidden; }
  @media (max-width: 768.98px) {
    .subpage-nav-aside {
      display: none; } }
  .subpage-nav-aside > ul {
    list-style-type: none;
    padding: 0; }
    .subpage-nav-aside > ul > li > a {
      position: relative;
      display: block;
      text-transform: uppercase;
      font-weight: 700;
      color: #525A70;
      min-height: 36px;
      padding: calc(0.35rem * 1.25) 1rem;
      font-size: 0.75rem;
      border-radius: 0; }
      .subpage-nav-aside > ul > li > a.active {
        color: #0093B2;
        background-color: #e6f4f7; }
        .subpage-nav-aside > ul > li > a.active::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 100%;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: #0093B2; }
      .subpage-nav-aside > ul > li > a:hover, .subpage-nav-aside > ul > li > a:focus {
        color: #1C355E;
        background-color: #e6f4f7; }
  .subpage-nav-aside.no-highlight > ul > li > a.active {
    background-color: transparent; }

@media (min-width: 769px) {
  .subpage-nav-aside-mobile {
    display: none; } }

.subpage-nav-aside-mobile .subpage-nav-aside-mobile-button {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #e6f4f7; }
  .subpage-nav-aside-mobile .subpage-nav-aside-mobile-button[aria-expanded="true"] .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .subpage-nav-aside-mobile .subpage-nav-aside-mobile-button[aria-expanded="true"] .hamburger-inner {
      width: 25px; }
    .subpage-nav-aside-mobile .subpage-nav-aside-mobile-button[aria-expanded="true"] .hamburger-inner:before {
      top: 0;
      opacity: 0;
      transition: top 0.15s ease, opacity 0.15s 0.12s ease; }
    .subpage-nav-aside-mobile .subpage-nav-aside-mobile-button[aria-expanded="true"] .hamburger-inner::after {
      width: 25px;
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.15s ease, transform 0.15s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .subpage-nav-aside-mobile .subpage-nav-aside-mobile-button[aria-expanded="true"] .hamburger-inner,
  .subpage-nav-aside-mobile .subpage-nav-aside-mobile-button[aria-expanded="true"] .hamburger-inner::before,
  .subpage-nav-aside-mobile .subpage-nav-aside-mobile-button[aria-expanded="true"] .hamburger-inner::after {
    background-color: #fff; }

.subpage-nav-aside-mobile .hamburger-box {
  width: 50px;
  min-width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #0093B2;
  border-top-left-radius: 5px; }

.subpage-nav-aside-mobile .hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .subpage-nav-aside-mobile .hamburger-inner, .subpage-nav-aside-mobile .hamburger-inner::before, .subpage-nav-aside-mobile .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute; }
  .subpage-nav-aside-mobile .hamburger-inner::before, .subpage-nav-aside-mobile .hamburger-inner::after {
    content: "";
    display: block; }
  .subpage-nav-aside-mobile .hamburger-inner::before {
    top: -7px;
    transition: top 0.15s 0.12s ease, opacity 0.175s ease; }
  .subpage-nav-aside-mobile .hamburger-inner::after {
    bottom: -7px;
    transition: bottom 0.15s 0.12s ease, transform 0.175s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.subpage-nav-aside-mobile .subpage-selection {
  width: 100%;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #0093B2;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.subpage-nav-aside-mobile .dropdown-menu {
  width: 100% !important;
  border-radius: 0; }
  .subpage-nav-aside-mobile .dropdown-menu > li > a {
    position: relative;
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    color: #525A70;
    min-height: 36px;
    padding: calc(0.35rem * 1.25) 1.5rem;
    font-size: 0.875rem;
    border-radius: 0; }
    .subpage-nav-aside-mobile .dropdown-menu > li > a.active {
      color: #0093B2;
      background-color: #e6f4f7; }
      .subpage-nav-aside-mobile .dropdown-menu > li > a.active::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #0093B2; }
    .subpage-nav-aside-mobile .dropdown-menu > li > a:hover, .subpage-nav-aside-mobile .dropdown-menu > li > a:focus {
      color: #1C355E;
      background-color: #e6f4f7; }

.smallheader-nav-mobile {
  position: fixed;
  z-index: 998;
  width: 100%;
  top: 50px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid #C8CED3; }
  @media (min-width: 769px) {
    .smallheader-nav-mobile {
      display: none; } }
  .smallheader-nav-mobile .smallheader-nav-mobile-back {
    position: absolute;
    z-index: 2;
    left: 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #C8CED3;
    border-bottom: 1px solid #C8CED3;
    background-color: #fff; }
  .smallheader-nav-mobile .smallheader-nav-mobile-button {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 3rem 0 1rem; }
    .smallheader-nav-mobile .smallheader-nav-mobile-button > span {
      margin-left: 50px;
      font-weight: 700;
      font-size: 0.875rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .smallheader-nav-mobile .smallheader-nav-mobile-button:after {
      content: "\e917";
      position: absolute;
      color: #0093B2;
      right: .5rem;
      font-family: 'peak-icons';
      font-size: 1.125rem;
      transition: transform .2s ease-in-out;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      line-height: 30px;
      text-align: center; }
    .smallheader-nav-mobile .smallheader-nav-mobile-button[aria-expanded="true"] {
      background-color: #e6f4f7;
      border-bottom: 1px solid #C8CED3; }
      .smallheader-nav-mobile .smallheader-nav-mobile-button[aria-expanded="true"]:after {
        transform: rotate(180deg);
        color: #fff;
        background-color: #0093B2; }
  .smallheader-nav-mobile .dropdown-menu {
    width: 100% !important;
    border-radius: 0;
    box-shadow: 0 1rem 3rem rgba(9, 18, 33, 0.175); }
    .smallheader-nav-mobile .dropdown-menu > li .dropdown-header {
      font-size: 0.875rem;
      font-weight: 700; }
    .smallheader-nav-mobile .dropdown-menu > li .dropdown-item {
      position: relative;
      font-size: 0.75rem;
      font-weight: 500;
      color: #525A70;
      border-bottom: 1px solid #C8CED3;
      padding: calc(0.25rem * 2) 2.25rem calc(0.25rem * 2) 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .smallheader-nav-mobile .dropdown-menu > li .dropdown-item:hover, .smallheader-nav-mobile .dropdown-menu > li .dropdown-item:focus {
        color: #1C355E;
        background-color: #e6f4f7; }
      .smallheader-nav-mobile .dropdown-menu > li .dropdown-item:after {
        content: "\e910";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #0093B2;
        right: .5rem;
        font-family: 'peak-icons';
        font-size: 1.125rem;
        transition: transform .2s ease-in-out;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
        text-align: center; }
    .smallheader-nav-mobile .dropdown-menu > li:last-child .dropdown-item {
      border-bottom: 0; }

.smallheader-nav-mobile-spacer {
  display: block;
  width: 100%;
  height: 50px; }
  @media (min-width: 769px) {
    .smallheader-nav-mobile-spacer {
      display: none; } }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0093B2;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #00768e; }
  .nav-link.disabled {
    color: #1C355E;
    pointer-events: none;
    cursor: default; }

.nav-tabs .nav-link {
  position: relative;
  color: #525A70;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: -1px;
  background: none;
  border: none;
  height: 60px; }
  @media (max-width: 768.98px) {
    .nav-tabs .nav-link {
      font-size: 0.75rem;
      height: 45px;
      padding: 0.5rem calc(1rem / 1.3333333333); } }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    isolation: isolate; }
  .nav-tabs .nav-link.disabled {
    color: #1C355E;
    background-color: transparent;
    border-color: transparent; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #0093B2; }
  .nav-tabs .nav-link.active:after,
  .nav-tabs .nav-item.show .nav-link:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 4px;
    background-color: #0093B2; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 5px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #e6f4f7; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 577px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 769px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 993px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1201px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1401px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 5px; }
  .card.card-shadow {
    box-shadow: 0 2px 6px rgba(9, 18, 33, 0.1); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 15px 30px; }
  @media (max-width: 768.98px) {
    .card-body {
      padding: 15px calc(30px / 2); } }
  .card-body.card-body-medium {
    padding: calc(15px * 2) 30px; }
    @media (max-width: 768.98px) {
      .card-body.card-body-medium {
        padding: calc(15px * 2) calc(30px / 2); } }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: 30px; }

.card-header {
  padding: 11.25px 30px;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  @media (max-width: 768.98px) {
    .card-header {
      padding: 15px calc(30px / 2); } }
  .card-header:first-child {
    border-radius: 4px 4px 0 0; }

.card-footer {
  padding: 11.25px 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 4px 4px; }

.card-header-tabs {
  margin-right: -15px;
  margin-bottom: -11.25px;
  margin-left: -15px;
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: #fff;
    border-bottom-color: #fff; }

.card-header-pills {
  margin-right: -15px;
  margin-left: -15px; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 4px; }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 577px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem 1rem calc(1.25rem * 2.5);
  font-size: 1rem;
  color: #112341;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 700;
  background-color: #e6f4f7;
  border: 0;
  border-radius: 5px;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #0084a0;
    background-color: #fdfefe; }
    .accordion-button:not(.collapsed)::before {
      background-color: #e6f4f7;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 120 120'%3e%3cg id='chevron-down' transform='translate%28-37.941 -87.94%29'%3e%3crect id='Rectangle_23' data-name='Rectangle 23' width='120' height='120' transform='translate%2837.94 87.94%29' fill='none'/%3e%3cg id='chevron-right' transform='translate%28-315.088 176.911%29'%3e%3crect id='Rectangle_3' data-name='Rectangle 3' width='106' height='106' transform='translate%28360.029 -81.971%29' fill='none'/%3e%3cpath id='Path_327' data-name='Path 327' d='M10.773,12.773a9.468,9.468,0,0,1,13.39,0L45.871,34.482,67.58,12.773A9.468,9.468,0,1,1,80.969,26.162l-28.4,28.4a9.468,9.468,0,0,1-13.389,0l-28.4-28.4A9.468,9.468,0,0,1,10.773,12.773Z' transform='translate%28367.158 -61.693%29' fill='%230093b2' fill-rule='evenodd'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
      transform: rotate(-180deg); }
  .accordion-button.collapsed .accordion-button .accordion-item {
    box-shadow: 0 2px 6px rgba(9, 18, 33, 0.1); }
  .accordion-button::before {
    position: absolute;
    left: .5rem;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    background-position: center;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 120 120'%3e%3cg id='chevron-down' transform='translate%28-37.941 -87.94%29'%3e%3crect id='Rectangle_23' data-name='Rectangle 23' width='120' height='120' transform='translate%2837.94 87.94%29' fill='none'/%3e%3cg id='chevron-right' transform='translate%28-315.088 176.911%29'%3e%3crect id='Rectangle_3' data-name='Rectangle 3' width='106' height='106' transform='translate%28360.029 -81.971%29' fill='none'/%3e%3cpath id='Path_327' data-name='Path 327' d='M10.773,12.773a9.468,9.468,0,0,1,13.39,0L45.871,34.482,67.58,12.773A9.468,9.468,0,1,1,80.969,26.162l-28.4,28.4a9.468,9.468,0,0,1-13.389,0l-28.4-28.4A9.468,9.468,0,0,1,10.773,12.773Z' transform='translate%28367.158 -61.693%29' fill='%230093b2' fill-rule='evenodd'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::before {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    outline: 0; }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: #fff;
  border: 0;
  border-radius: 5px;
  margin-bottom: .5rem; }
  .accordion-item.active {
    box-shadow: 0 2px 10px rgba(9, 18, 33, 0.1); }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #1C355E;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #1C355E; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #0093B2;
  background-color: #fff;
  border: 1px solid #C8CED3;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #00768e;
    background-color: #F2F5F8;
    border-color: #C8CED3; }
  .page-link:focus {
    z-index: 3;
    color: #00768e;
    background-color: #F2F5F8;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(230, 244, 247, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #e6f4f7;
  border-color: #e6f4f7; }

.page-item.disabled .page-link {
  color: #1C355E;
  pointer-events: none;
  background-color: #fff;
  border-color: #C8CED3; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px; }
  .badge:empty {
    display: none; }

.btn .badge, .btn-outline .badge, .btn-outline-secondary .badge {
  position: relative;
  top: -1px; }

.legend-badge {
  display: inline-block;
  width: 20px;
  height: 8px;
  border-radius: 5px;
  background-color: #98A4AE;
  margin-right: .5em; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  line-height: 1.25;
  border-radius: 5px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 500; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 50%;
    right: .75rem;
    transform: translateY(-50%);
    z-index: 2;
    padding: .325rem;
    opacity: 1;
    background-size: 22px;
    background-color: #fff;
    border-radius: 50%; }

.alert-primary {
  color: #1C355E;
  background-color: #0093b2;
  font-size: 0.875rem; }
  .alert-primary .alert-link {
    color: #112038; }

.alert-primary, .alert-secondary, .alert-danger, .alert-dark {
  color: #fff; }

.alert-secondary {
  color: #1C355E;
  background-color: #1c355e;
  font-size: 0.875rem; }
  .alert-secondary .alert-link {
    color: #112038; }

.alert-primary, .alert-secondary, .alert-danger, .alert-dark {
  color: #fff; }

.alert-success {
  color: #1C355E;
  background-color: #3cce07;
  font-size: 0.875rem; }
  .alert-success .alert-link {
    color: #112038; }

.alert-primary, .alert-secondary, .alert-danger, .alert-dark {
  color: #fff; }

.alert-info {
  color: #1C355E;
  background-color: #fff6e7;
  font-size: 0.875rem; }
  .alert-info .alert-link {
    color: #112038; }

.alert-primary, .alert-secondary, .alert-danger, .alert-dark {
  color: #fff; }

.alert-warning {
  color: #1C355E;
  background-color: #fbe122;
  font-size: 0.875rem; }
  .alert-warning .alert-link {
    color: #112038; }

.alert-primary, .alert-secondary, .alert-danger, .alert-dark {
  color: #fff; }

.alert-danger {
  color: #1C355E;
  background-color: #f70000;
  font-size: 0.875rem; }
  .alert-danger .alert-link {
    color: #112038; }

.alert-primary, .alert-secondary, .alert-danger, .alert-dark {
  color: #fff; }

.alert-light {
  color: #1C355E;
  background-color: #f2f5f8;
  font-size: 0.875rem; }
  .alert-light .alert-link {
    color: #112038; }

.alert-primary, .alert-secondary, .alert-danger, .alert-dark {
  color: #fff; }

.alert-white {
  color: #1C355E;
  background-color: white;
  font-size: 0.875rem; }
  .alert-white .alert-link {
    color: #112038; }

.alert-primary, .alert-secondary, .alert-danger, .alert-dark {
  color: #fff; }

.alert-dark {
  color: #1C355E;
  background-color: #1c355e;
  font-size: 0.875rem; }
  .alert-dark .alert-link {
    color: #112038; }

.alert-primary, .alert-secondary, .alert-danger, .alert-dark {
  color: #fff; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #F2F5F8;
  border-radius: 5px; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0093B2;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 5px; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #112341;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #112341;
    text-decoration: none;
    background-color: #FAFCFE; }
  .list-group-item-action:active {
    color: #112341;
    background-color: #F2F5F8; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #091221;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #1C355E;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #e6f4f7;
    border-color: #e6f4f7; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 577px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 769px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 993px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1201px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1401px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #00586b;
  background-color: #cce9f0; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #00586b;
    background-color: #b8d2d8; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #00586b;
    border-color: #00586b; }

.list-group-item-secondary {
  color: #112038;
  background-color: #d2d7df; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #112038;
    background-color: #bdc2c9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #112038;
    border-color: #112038; }

.list-group-item-success {
  color: #247c04;
  background-color: #d8f5cd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #247c04;
    background-color: #c2ddb9; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #247c04;
    border-color: #247c04; }

.list-group-item-info {
  color: #66625c;
  background-color: #fffdfa; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #66625c;
    background-color: #e6e4e1; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #66625c;
    border-color: #66625c; }

.list-group-item-warning {
  color: #645a0e;
  background-color: #fef9d3; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #645a0e;
    background-color: #e5e0be; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #645a0e;
    border-color: #645a0e; }

.list-group-item-danger {
  color: #940000;
  background-color: #fdcccc; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #940000;
    background-color: #e4b8b8; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #940000;
    border-color: #940000; }

.list-group-item-light {
  color: #616263;
  background-color: #fcfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #616263;
    background-color: #e3e4e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #616263;
    border-color: #616263; }

.list-group-item-white {
  color: #666666;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #666666;
    background-color: #e6e6e6; }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }

.list-group-item-dark {
  color: #112038;
  background-color: #d2d7df; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #112038;
    background-color: #bdc2c9; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #112038;
    border-color: #112038; }

.list-table-header {
  color: #fff;
  min-height: 50px;
  background-color: #1C355E;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border-radius: 5px; }
  .list-table-header .list-table-title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0; }
    @media (max-width: 576.98px) {
      .list-table-header .list-table-title {
        font-size: 0.875rem; } }

.list-table {
  list-style-type: none;
  counter-reset: section;
  padding: 0;
  margin-bottom: 30px; }
  .list-table > li {
    padding: 20px;
    border-radius: 5px; }
    .list-table > li:nth-child(even) {
      background-color: #F2F5F8; }
    .list-table > li .row {
      border-top: 1px solid #C8CED3; }
      .list-table > li .row:first-child {
        border: none; }

.legend-list {
  list-style-type: none;
  counter-reset: section;
  padding: 0; }
  .legend-list > li {
    font-size: 0.75rem;
    font-weight: 700;
    color: #525A70; }

.plans-list {
  list-style-type: none;
  counter-reset: section;
  padding: 0; }
  .plans-list .plan-item > a {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 65px;
    border-radius: 5px;
    border: 1px solid #C8CED3;
    padding: .25rem 4rem .25rem 20px;
    color: #1C355E;
    font-weight: 500;
    font-size: 0.875rem;
    text-decoration: none;
    margin-bottom: 10px;
    background-color: #fff; }
    .plans-list .plan-item > a:hover {
      background-color: #e6f4f7; }
      .plans-list .plan-item > a:hover .i-circle-rounded {
        color: #fff;
        background-color: #0093B2; }
    @media (max-width: 576.98px) {
      .plans-list .plan-item > a {
        flex-direction: column;
        height: 55px;
        padding: .25rem 3rem .25rem 15px; } }
    .plans-list .plan-item > a .i-circle-rounded {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%); }
      @media (max-width: 576.98px) {
        .plans-list .plan-item > a .i-circle-rounded {
          right: 15px; } }
    .plans-list .plan-item > a .plan-item-name {
      flex: 0 0 auto;
      width: 40%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      @media (max-width: 576.98px) {
        .plans-list .plan-item > a .plan-item-name {
          width: 100%; } }
    .plans-list .plan-item > a .plan-item-end {
      flex: 0 0 auto;
      width: 60%;
      display: flex;
      align-items: center; }
      @media (max-width: 576.98px) {
        .plans-list .plan-item > a .plan-item-end {
          width: 100%; } }
      .plans-list .plan-item > a .plan-item-end .plan-item-id, .plans-list .plan-item > a .plan-item-end .plan-item-holder {
        text-align: right;
        padding: 0 10px; }
        .plans-list .plan-item > a .plan-item-end .plan-item-id > span, .plans-list .plan-item > a .plan-item-end .plan-item-holder > span {
          display: block;
          line-height: 1.25;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
          .plans-list .plan-item > a .plan-item-end .plan-item-id > span:first-child, .plans-list .plan-item > a .plan-item-end .plan-item-holder > span:first-child {
            font-weight: 400; }
      .plans-list .plan-item > a .plan-item-end .plan-item-id {
        flex: 0 0 auto;
        width: 20%; }
        @media (max-width: 992.98px) {
          .plans-list .plan-item > a .plan-item-end .plan-item-id {
            display: none; } }
      .plans-list .plan-item > a .plan-item-end .plan-item-holder {
        flex: 0 0 auto;
        width: 40%; }
        @media (min-width: 769px) and (max-width: 992.98px) {
          .plans-list .plan-item > a .plan-item-end .plan-item-holder {
            width: 50%; } }
        @media (max-width: 768.98px) {
          .plans-list .plan-item > a .plan-item-end .plan-item-holder {
            display: none; } }
      .plans-list .plan-item > a .plan-item-end .plan-item-value {
        flex: 0 0 auto;
        width: 40%;
        display: flex;
        text-align: right;
        justify-content: end;
        align-items: center;
        white-space: nowrap; }
        @media (min-width: 769px) and (max-width: 992.98px) {
          .plans-list .plan-item > a .plan-item-end .plan-item-value {
            width: 50%; } }
        @media (min-width: 577px) and (max-width: 768.98px) {
          .plans-list .plan-item > a .plan-item-end .plan-item-value {
            justify-content: end;
            width: 100%;
            text-align: right; } }
        @media (max-width: 576.98px) {
          .plans-list .plan-item > a .plan-item-end .plan-item-value {
            justify-content: start;
            width: 100%;
            text-align: left; } }
        .plans-list .plan-item > a .plan-item-end .plan-item-value > span {
          font-size: 1.125rem;
          font-weight: 700; }
          @media (max-width: 576.98px) {
            .plans-list .plan-item > a .plan-item-end .plan-item-value > span {
              font-size: 0.875rem; } }
          .plans-list .plan-item > a .plan-item-end .plan-item-value > span > sub {
            font-weight: 400; }

.btn-close {
  box-sizing: content-box;
  width: 1rem;
  height: 1rem;
  padding: 0.25em 0.25em;
  color: #fff;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 120 120'%3e%3cg id='close' transform='translate%28-37.941 -87.94%29'%3e%3crect id='Rectangle_23' data-name='Rectangle 23' width='120' height='120' transform='translate%2837.94 87.94%29' fill='none'/%3e%3cg id='sqaure' transform='translate%2855.708 -251.827%29 rotate%2890%29'%3e%3crect id='Rectangle_3' data-name='Rectangle 3' width='80' height='80' transform='translate%28359.767 -82.233%29' fill='none'/%3e%3cpath id='Path_900' data-name='Path 900' d='M2.68,2.68a9.15,9.15,0,0,1,12.939,0L27.448,14.509,39.277,2.68A9.149,9.149,0,0,1,52.216,15.619L40.387,27.448,52.216,39.277A9.149,9.149,0,0,1,39.277,52.216L27.448,40.387,15.619,52.216A9.149,9.149,0,0,1,2.68,39.277L14.509,27.448,2.68,15.619A9.15,9.15,0,0,1,2.68,2.68Z' transform='translate%28372.319 -14.785%29 rotate%28-90%29' fill='%231c355e' fill-rule='evenodd'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e") center/1rem auto no-repeat;
  border: 0;
  border-radius: 5px;
  opacity: 0.5; }
  .btn-close:hover {
    color: #fff;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(230, 244, 247, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(9, 18, 33, 0.1);
  border-radius: 5px; }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 0.75rem; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #1C355E;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.15s ease-out; }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #091221; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #C8CED3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }
  .modal-header.modal-header-center {
    position: relative;
    justify-content: space-between; }
    .modal-header.modal-header-center .close {
      display: flex;
      align-items: center;
      width: 25%; }
      @media (max-width: 576.98px) {
        .modal-header.modal-header-center .close {
          position: absolute;
          left: 1rem;
          width: auto; } }
    .modal-header.modal-header-center .title {
      width: 50%;
      text-align: center; }
      .modal-header.modal-header-center .title > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      @media (max-width: 576.98px) {
        .modal-header.modal-header-center .title {
          padding-left: calc(1rem * 2.5);
          text-align: left;
          width: 65%; } }
    .modal-header.modal-header-center .end {
      width: 25%;
      text-align: right; }
      @media (max-width: 576.98px) {
        .modal-header.modal-header-center .end {
          width: 35%; } }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #C8CED3;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 577px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 993px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1201px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-dialog.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-dialog.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-dialog.modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-dialog.modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-dialog.modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 576.98px) {
  .modal-dialog.modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-dialog.modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-dialog.modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-dialog.modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-dialog.modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 768.98px) {
  .modal-dialog.modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-dialog.modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-dialog.modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-dialog.modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-dialog.modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 992.98px) {
  .modal-dialog.modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-dialog.modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-dialog.modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-dialog.modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-dialog.modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1200.98px) {
  .modal-dialog.modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-dialog.modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-dialog.modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-dialog.modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-dialog.modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1400.98px) {
  .modal-dialog.modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-dialog.modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-dialog.modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-dialog.modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-dialog.modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 5px; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #112341; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.4s cubic-bezier(0.83, 0, 0.17, 1); }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #091221; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-body-inner {
  padding: 1rem 1rem; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.offcanvas-ttmadvisor {
  width: 100%;
  max-width: 680px; }
  .offcanvas-ttmadvisor .offcanvas-header {
    position: relative;
    justify-content: center;
    background-color: #1C355E; }
    .offcanvas-ttmadvisor .offcanvas-header .close-btn {
      position: absolute;
      left: 1rem; }
    .offcanvas-ttmadvisor .offcanvas-header .heading {
      color: #fff;
      margin-bottom: 0; }
  .offcanvas-ttmadvisor .offcanvas-body {
    padding: 0; }
    .offcanvas-ttmadvisor .offcanvas-body .myadvisor {
      padding: 15px 0; }
      .offcanvas-ttmadvisor .offcanvas-body .myadvisor .myadvisor-wrapper .myadvisor-item {
        flex: 0 0 auto;
        width: 100%; }
        .offcanvas-ttmadvisor .offcanvas-body .myadvisor .myadvisor-wrapper .myadvisor-item:not(:only-child) {
          flex: 0 0 50%;
          width: 50%; }
        @media (max-width: 576.98px) {
          .offcanvas-ttmadvisor .offcanvas-body .myadvisor .myadvisor-wrapper .myadvisor-item:not(:only-child) {
            flex: 0 0 100%;
            width: 100%; } }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5; }
  .placeholder.btn::before, .placeholder.btn-outline::before, .placeholder.btn-outline-secondary::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

@font-face {
  font-family: 'peak-icons';
  src: url("/assets/icons/peak-icons.eot?yz7so3");
  src: url("/assets/icons/peak-icons.eot?yz7so3#iefix") format("embedded-opentype"), url("/assets/icons/peak-icons.ttf?yz7so3") format("truetype"), url("/assets/icons/peak-icons.woff?yz7so3") format("woff"), url("/assets/icons/peak-icons.svg?yz7so3#peak-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="peak-icon-"], [class*=" peak-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'peak-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.i-size-xxs {
  font-size: .25rem !important; }

.i-size-xs {
  font-size: .5rem !important; }

.i-size-sm {
  font-size: .75rem !important; }

.i-size-default {
  font-size: 1rem !important; }

.i-size-md {
  font-size: 1.25rem !important; }

.i-size-lg {
  font-size: 1.5rem !important; }

.i-size-xl {
  font-size: 1.75rem !important; }

.i-size-xxl {
  font-size: 2rem !important; }

.i-size-xxxl {
  font-size: 2.25rem !important; }

.i-circle-rounded {
  font-size: 1.125rem;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  color: #0093B2;
  background-color: #e6f4f7;
  transition: all 0.15s ease-in-out; }

.peak-icon-arrow-switch:before {
  content: "\e945"; }

.peak-icon-star-hidden:before {
  content: "\e93d"; }

.peak-icon-star:before {
  content: "\e93e"; }

.peak-icon-list:before {
  content: "\e93f"; }

.peak-icon-eye-hidden:before {
  content: "\e940"; }

.peak-icon-eye:before {
  content: "\e941"; }

.peak-icon-filter:before {
  content: "\e942"; }

.peak-icon-client-circle:before {
  content: "\e943"; }

.peak-icon-target:before {
  content: "\e944"; }

.peak-icon-shield-money:before {
  content: "\e920"; }

.peak-icon-shield:before {
  content: "\e921"; }

.peak-icon-text-justified:before {
  content: "\e922"; }

.peak-icon-text-right:before {
  content: "\e923"; }

.peak-icon-text-center:before {
  content: "\e924"; }

.peak-icon-text-left:before {
  content: "\e925"; }

.peak-icon-folder-money:before {
  content: "\e926"; }

.peak-icon-search:before {
  content: "\e927"; }

.peak-icon-unlock:before {
  content: "\e928"; }

.peak-icon-contract:before {
  content: "\e929"; }

.peak-icon-file-upload:before {
  content: "\e92a"; }

.peak-icon-file-note:before {
  content: "\e92b"; }

.peak-icon-check-square:before {
  content: "\e92c"; }

.peak-icon-check-mark:before {
  content: "\e92d"; }

.peak-icon-bar-chart:before {
  content: "\e92e"; }

.peak-icon-pie-chart:before {
  content: "\e92f"; }

.peak-icon-plus:before {
  content: "\e930"; }

.peak-icon-clock:before {
  content: "\e931"; }

.peak-icon-mail-out:before {
  content: "\e932"; }

.peak-icon-mail-in:before {
  content: "\e933"; }

.peak-icon-calendar:before {
  content: "\e934"; }

.peak-icon-download:before {
  content: "\e935"; }

.peak-icon-upload:before {
  content: "\e936"; }

.peak-icon-client:before {
  content: "\e937"; }

.peak-icon-trash:before {
  content: "\e938"; }

.peak-icon-refresh:before {
  content: "\e939"; }

.peak-icon-map-marker:before {
  content: "\e93a"; }

.peak-icon-pencil:before {
  content: "\e93b"; }

.peak-icon-clients:before {
  content: "\e91f"; }

.peak-icon-lock:before {
  content: "\e91c"; }

.peak-icon-files:before {
  content: "\e91d"; }

.peak-icon-globe:before {
  content: "\e91e"; }

.peak-icon-suitcase:before {
  content: "\e91a"; }

.peak-icon-mail-check:before {
  content: "\e91b"; }

.peak-icon-phone:before {
  content: "\e919"; }

.peak-icon-linkedin:before {
  content: "\e918"; }

.peak-icon-arrow-right:before {
  content: "\e90f"; }

.peak-icon-chevron-right:before {
  content: "\e910"; }

.peak-icon-chevron-up:before {
  content: "\e911"; }

.peak-icon-arrow-down:before {
  content: "\e912"; }

.peak-icon-arrow-left:before {
  content: "\e914"; }

.peak-icon-arrow-up:before {
  content: "\e915"; }

.peak-icon-chevron-left:before {
  content: "\e916"; }

.peak-icon-chevron-down:before {
  content: "\e917"; }

.peak-icon-close:before {
  content: "\e913"; }

.peak-icon-advisor:before {
  content: "\e900"; }

.peak-icon-bar-chart-icon:before {
  content: "\e901"; }

.peak-icon-bell:before {
  content: "\e902"; }

.peak-icon-chat:before {
  content: "\e903"; }

.peak-icon-dashboard:before {
  content: "\e904"; }

.peak-icon-faq:before {
  content: "\e905"; }

.peak-icon-file-download:before {
  content: "\e906"; }

.peak-icon-folder:before {
  content: "\e907"; }

.peak-icon-gear:before {
  content: "\e908"; }

.peak-icon-mail:before {
  content: "\e909"; }

.peak-icon-pie-chart-icon:before {
  content: "\e90a"; }

.peak-icon-portfolio:before {
  content: "\e90b"; }

.peak-icon-power:before {
  content: "\e90c"; }

.peak-icon-printer:before {
  content: "\e90d"; }

.peak-icon-quotes:before {
  content: "\e90e"; }

.main-wrapper {
  display: flex; }
  .main-wrapper .inner-wrapper {
    width: 100%;
    margin-top: 60px;
    margin-left: 100px; }
    @media (max-width: 768.98px) {
      .main-wrapper .inner-wrapper {
        margin-top: 50px;
        margin-left: 0; } }
    .main-wrapper .inner-wrapper .content-wrapper {
      padding-left: 60px;
      padding-right: 60px;
      width: 100%;
      max-width: 1600px;
      margin: 0 auto; }
      @media (min-width: 769px) and (max-width: 992.98px) {
        .main-wrapper .inner-wrapper .content-wrapper {
          padding-left: 30px;
          padding-right: 30px; } }
      @media (max-width: 768.98px) {
        .main-wrapper .inner-wrapper .content-wrapper {
          padding-left: 15px;
          padding-right: 15px; } }
    .main-wrapper .inner-wrapper .page-header {
      padding-left: 60px;
      padding-right: 60px;
      width: 100%;
      height: 75px;
      background-color: #fff;
      border-bottom: 1px solid #C8CED3;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (min-width: 769px) and (max-width: 992.98px) {
        .main-wrapper .inner-wrapper .page-header {
          padding-left: 30px;
          padding-right: 30px; } }
      @media (max-width: 768.98px) {
        .main-wrapper .inner-wrapper .page-header {
          padding-left: 15px;
          padding-right: 15px; } }
      @media (max-width: 768.98px) {
        .main-wrapper .inner-wrapper .page-header {
          height: 55px; } }
      .main-wrapper .inner-wrapper .page-header > h1, .main-wrapper .inner-wrapper .page-header > .h1,
      .main-wrapper .inner-wrapper .page-header > .heading-xxxlarge {
        color: #1C355E;
        font-size: 1.875rem;
        margin-bottom: 0; }
        @media (max-width: 768.98px) {
          .main-wrapper .inner-wrapper .page-header > h1, .main-wrapper .inner-wrapper .page-header > .h1,
          .main-wrapper .inner-wrapper .page-header > .heading-xxxlarge {
            font-size: 1.25rem; } }
    .main-wrapper .inner-wrapper .small-header {
      padding-left: 60px;
      padding-right: 60px;
      width: 100%;
      height: 60px;
      background-color: #fff;
      border-bottom: 1px solid #C8CED3;
      display: flex;
      align-items: center; }
      @media (min-width: 769px) and (max-width: 992.98px) {
        .main-wrapper .inner-wrapper .small-header {
          padding-left: 30px;
          padding-right: 30px; } }
      @media (max-width: 768.98px) {
        .main-wrapper .inner-wrapper .small-header {
          padding-left: 15px;
          padding-right: 15px; } }
      @media (max-width: 768.98px) {
        .main-wrapper .inner-wrapper .small-header {
          height: 55px; } }
      .main-wrapper .inner-wrapper .small-header h3, .main-wrapper .inner-wrapper .small-header .h3,
      .main-wrapper .inner-wrapper .small-header .heading-xlarge {
        color: #1C355E;
        font-weight: 700;
        font-size: 1.125rem;
        margin-bottom: 0; }
      .main-wrapper .inner-wrapper .small-header > a {
        display: flex;
        align-items: center; }
    .main-wrapper .inner-wrapper .subpage-wrapper {
      display: flex; }
      @media (max-width: 768.98px) {
        .main-wrapper .inner-wrapper .subpage-wrapper {
          flex-direction: column; } }
      .main-wrapper .inner-wrapper .subpage-wrapper .subpage-aside {
        height: 100%;
        width: 100%;
        max-width: 200px; }
        @media (max-width: 768.98px) {
          .main-wrapper .inner-wrapper .subpage-wrapper .subpage-aside {
            max-width: unset; } }
      .main-wrapper .inner-wrapper .subpage-wrapper > section {
        width: 100%;
        border-left: 1px solid #C8CED3; }
        @media (max-width: 768.98px) {
          .main-wrapper .inner-wrapper .subpage-wrapper > section {
            border-left: none;
            border-top: 1px solid #C8CED3; } }

.footer {
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  height: 70px;
  margin-top: 150px;
  background-color: #fff;
  border-top: 1px solid #C8CED3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px; }
  @media (min-width: 769px) and (max-width: 992.98px) {
    .footer {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (max-width: 768.98px) {
    .footer {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (max-width: 768.98px) {
    .footer {
      height: auto;
      flex-direction: column;
      margin-top: 100px;
      padding-top: 30px;
      padding-bottom: 30px; } }
  .footer .footer-disclaimer {
    color: #525A70;
    font-size: 0.875rem; }
    @media (max-width: 768.98px) {
      .footer .footer-disclaimer {
        margin-top: 30px;
        order: 2; } }
  @media (max-width: 768.98px) {
    .footer .footer-menu {
      order: 1;
      text-align: center;
      width: 100%; } }
  .footer .footer-menu > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center; }
    @media (max-width: 768.98px) {
      .footer .footer-menu > ul > li {
        flex: 0 0 auto;
        width: 33.333333%; } }
    .footer .footer-menu > ul > li > a {
      display: block;
      color: #525A70;
      font-size: 0.875rem;
      line-height: 1.25; }
      .footer .footer-menu > ul > li > a:hover {
        color: #98A4AE; }
    .footer .footer-menu > ul > li:not(:last-child) > a {
      margin-right: 40px; }
      @media (max-width: 768.98px) {
        .footer .footer-menu > ul > li:not(:last-child) > a {
          margin-right: 0;
          margin-left: 0; } }

.myadvisor {
  background-color: #112341;
  width: 100%; }
  .myadvisor .myadvisor-wrapper {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 576.98px) {
      .myadvisor .myadvisor-wrapper {
        flex-direction: column; } }
    .myadvisor .myadvisor-wrapper .myadvisor-item {
      display: flex;
      flex-direction: column;
      padding: 15px 1rem; }
      .myadvisor .myadvisor-wrapper .myadvisor-item ~ .myadvisor-item {
        flex: 1 0 0%; }
        @media (max-width: 576.98px) {
          .myadvisor .myadvisor-wrapper .myadvisor-item ~ .myadvisor-item {
            flex: 1 0 0;
            width: 100%;
            border-top: 1px solid rgba(255, 255, 255, 0.25); } }
      .myadvisor .myadvisor-wrapper .myadvisor-item .header {
        display: flex;
        align-items: center; }
        @media only screen and (max-width: 375px) {
          .myadvisor .myadvisor-wrapper .myadvisor-item .header {
            align-items: flex-start;
            flex-direction: column; } }
        .myadvisor .myadvisor-wrapper .myadvisor-item .header .avatar {
          text-align: center;
          margin-right: .5rem; }
          @media (max-width: 576.98px) {
            .myadvisor .myadvisor-wrapper .myadvisor-item .header .avatar {
              align-items: flex-start;
              flex-direction: column;
              margin-bottom: 5px; } }
          .myadvisor .myadvisor-wrapper .myadvisor-item .header .avatar .avatar-img {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 95px;
            height: 95px;
            border-radius: 50%;
            border: 4px solid #C8CED3;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            background-color: #0093B2; }
            @media (max-width: 576.98px) {
              .myadvisor .myadvisor-wrapper .myadvisor-item .header .avatar .avatar-img {
                width: 75px;
                height: 75px; } }
            .myadvisor .myadvisor-wrapper .myadvisor-item .header .avatar .avatar-img > i {
              font-size: 2.5rem;
              color: #fff; }
          .myadvisor .myadvisor-wrapper .myadvisor-item .header .avatar .avatar-social {
            font-size: 1.75rem; }
            @media (max-width: 768.98px) {
              .myadvisor .myadvisor-wrapper .myadvisor-item .header .avatar .avatar-social {
                display: none; } }
        .myadvisor .myadvisor-wrapper .myadvisor-item .header .info .name {
          line-height: 1;
          text-transform: capitalize;
          color: #fff;
          display: block;
          font-weight: 700; }
        .myadvisor .myadvisor-wrapper .myadvisor-item .header .info .title {
          display: block;
          color: #F2F5F8;
          font-size: 0.75rem; }
        .myadvisor .myadvisor-wrapper .myadvisor-item .header .info .handles {
          margin-top: 3px; }
          .myadvisor .myadvisor-wrapper .myadvisor-item .header .info .handles > a > i {
            font-size: 1.425rem; }
      .myadvisor .myadvisor-wrapper .myadvisor-item .details {
        padding-left: .5rem;
        color: #fff; }
        @media (max-width: 576.98px) {
          .myadvisor .myadvisor-wrapper .myadvisor-item .details {
            padding-left: 0; } }
        .myadvisor .myadvisor-wrapper .myadvisor-item .details .contact-info {
          margin-top: 10px; }
          @media (max-width: 576.98px) {
            .myadvisor .myadvisor-wrapper .myadvisor-item .details .contact-info {
              margin-top: 5px; } }
          .myadvisor .myadvisor-wrapper .myadvisor-item .details .contact-info > a {
            display: block;
            margin-bottom: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #fff; }
            .myadvisor .myadvisor-wrapper .myadvisor-item .details .contact-info > a > i {
              color: #0093B2;
              font-size: 1.25rem;
              vertical-align: middle;
              margin-right: 3px; }
            .myadvisor .myadvisor-wrapper .myadvisor-item .details .contact-info > a > span {
              color: #fff;
              font-size: 0.875rem; }

.advisor-help {
  background-color: #F2F5F8;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 768.98px) {
    .advisor-help {
      flex-direction: column;
      justify-content: center; } }
  .advisor-help .advisor-help-start {
    display: flex;
    align-items: center;
    padding-right: 15px; }
    @media (max-width: 768.98px) {
      .advisor-help .advisor-help-start {
        flex-direction: column;
        justify-content: center;
        text-align: center; }
        .advisor-help .advisor-help-start > div {
          margin-bottom: 15px; } }
    .advisor-help .advisor-help-start > i {
      width: 56px;
      height: 56px;
      line-height: 56px;
      border-radius: 50%;
      text-align: center;
      background-color: #e6f4f7;
      color: #0093B2;
      margin-right: 10px;
      font-size: 2rem; }
  .advisor-help .advisor-help-end > .btn, .advisor-help .advisor-help-end > .btn-outline, .advisor-help .advisor-help-end > .btn-outline-secondary {
    margin: 10px 0; }

.timeline-v-chart {
  width: 100%; }
  .timeline-v-chart > ul {
    position: relative;
    list-style-type: none;
    margin: 0;
    padding: 1px 0; }
    .timeline-v-chart > ul:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 3px;
      height: 100%;
      background-color: #7EDDD3;
      z-index: 0;
      border-radius: 5px; }
    .timeline-v-chart > ul > li {
      display: flex;
      align-items: center;
      margin: 6px 0; }
      .timeline-v-chart > ul > li > span {
        flex: 0 0 auto;
        width: calc(50% - 6px);
        font-size: 0.75rem; }
        .timeline-v-chart > ul > li > span:first-child {
          text-align: right;
          padding-right: 8px; }
        .timeline-v-chart > ul > li > span:last-child {
          font-weight: 700;
          padding-left: 8px; }
      .timeline-v-chart > ul > li .dot {
        position: relative;
        z-index: 1;
        flex: 0 0 auto;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #7EDDD3;
        border: 2px solid #fff; }

.exc-rate {
  display: flex;
  align-items: center; }
  .exc-rate-toggle {
    cursor: pointer; }
  .exc-rate-title {
    display: block;
    line-height: 1; }
  .exc-rate-container {
    position: relative;
    margin-left: 10px;
    display: flex;
    align-items: center; }
    .exc-rate-container > i {
      margin: 0 3px; }
  .exc-rate-value {
    margin-left: 10px;
    font-weight: 700;
    font-size: 0.875rem; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #0093B2;
  text-decoration: underline; }
  .link-primary:hover, .link-primary:focus {
    color: #33a9c1; }

.link-secondary {
  color: #1C355E;
  text-decoration: underline; }
  .link-secondary:hover, .link-secondary:focus {
    color: #162a4b; }

.link-success {
  color: #3CCE07;
  text-decoration: underline; }
  .link-success:hover, .link-success:focus {
    color: #63d839; }

.link-info {
  color: #FFF6E7;
  text-decoration: underline; }
  .link-info:hover, .link-info:focus {
    color: #fff8ec; }

.link-warning {
  color: #FBE122;
  text-decoration: underline; }
  .link-warning:hover, .link-warning:focus {
    color: #fce74e; }

.link-danger {
  color: #F70000;
  text-decoration: underline; }
  .link-danger:hover, .link-danger:focus {
    color: #f93333; }

.link-light {
  color: #F2F5F8;
  text-decoration: underline; }
  .link-light:hover, .link-light:focus {
    color: #f5f7f9; }

.link-white {
  color: #fff;
  text-decoration: underline; }
  .link-white:hover, .link-white:focus {
    color: white; }

.link-dark {
  color: #1C355E;
  text-decoration: underline; }
  .link-dark:hover, .link-dark:focus {
    color: #162a4b; }

.color-primary-blue {
  color: #0093B2 !important; }

.color-blue {
  color: #4E7AFC !important; }

.color-blue-light {
  color: #45BFE2 !important; }

.color-indigo {
  color: #6610f2 !important; }

.color-purple {
  color: #8565D5 !important; }

.color-pink {
  color: #d63384 !important; }

.color-red {
  color: #F70000 !important; }

.color-orange {
  color: #FC8667 !important; }

.color-orange-light {
  color: #F8B73B !important; }

.color-fire {
  color: #FA4616 !important; }

.color-yellow {
  color: #FBE122 !important; }

.color-green {
  color: #3CCE07 !important; }

.color-green-dark {
  color: #25B082 !important; }

.color-teal {
  color: #7EDDD3 !important; }

.color-cyan {
  color: #0dcaf0 !important; }

.color-beige {
  color: #FFF6E7 !important; }

.color-white {
  color: #fff !important; }

.color-white-gray {
  color: #FAFCFE !important; }

.color-light {
  color: #F2F5F8 !important; }

.color-gray-light {
  color: #C8CED3 !important; }

.color-gray {
  color: #98A4AE !important; }

.color-gray-dark {
  color: #525A70 !important; }

.color-dark {
  color: #1C355E !important; }

.color-darker {
  color: #112341 !important; }

.color-darkest {
  color: #091221 !important; }

.bgcolor-primary-blue {
  background-color: #0093B2 !important; }

.bgcolor-blue {
  background-color: #4E7AFC !important; }

.bgcolor-blue-light {
  background-color: #45BFE2 !important; }

.bgcolor-indigo {
  background-color: #6610f2 !important; }

.bgcolor-purple {
  background-color: #8565D5 !important; }

.bgcolor-pink {
  background-color: #d63384 !important; }

.bgcolor-red {
  background-color: #F70000 !important; }

.bgcolor-orange {
  background-color: #FC8667 !important; }

.bgcolor-orange-light {
  background-color: #F8B73B !important; }

.bgcolor-fire {
  background-color: #FA4616 !important; }

.bgcolor-yellow {
  background-color: #FBE122 !important; }

.bgcolor-green {
  background-color: #3CCE07 !important; }

.bgcolor-green-dark {
  background-color: #25B082 !important; }

.bgcolor-teal {
  background-color: #7EDDD3 !important; }

.bgcolor-cyan {
  background-color: #0dcaf0 !important; }

.bgcolor-beige {
  background-color: #FFF6E7 !important; }

.bgcolor-white {
  background-color: #fff !important; }

.bgcolor-white-gray {
  background-color: #FAFCFE !important; }

.bgcolor-light {
  background-color: #F2F5F8 !important; }

.bgcolor-gray-light {
  background-color: #C8CED3 !important; }

.bgcolor-gray {
  background-color: #98A4AE !important; }

.bgcolor-gray-dark {
  background-color: #525A70 !important; }

.bgcolor-dark {
  background-color: #1C355E !important; }

.bgcolor-darker {
  background-color: #112341 !important; }

.bgcolor-darkest {
  background-color: #091221 !important; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 577px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 769px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 993px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1201px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1401px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 2px 6px rgba(9, 18, 33, 0.1) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(9, 18, 33, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(9, 18, 33, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #C8CED3 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #C8CED3 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #C8CED3 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #C8CED3 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #C8CED3 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0093B2 !important; }

.border-secondary {
  border-color: #1C355E !important; }

.border-success {
  border-color: #3CCE07 !important; }

.border-info {
  border-color: #FFF6E7 !important; }

.border-warning {
  border-color: #FBE122 !important; }

.border-danger {
  border-color: #F70000 !important; }

.border-light {
  border-color: #F2F5F8 !important; }

.border-white {
  border-color: #fff !important; }

.border-dark {
  border-color: #1C355E !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.425rem + 2.1vw) !important; }

.fs-2 {
  font-size: calc(1.35rem + 1.2vw) !important; }

.fs-3 {
  font-size: calc(1.3125rem + 0.75vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1.125rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 500 !important; }

.fw-bolder {
  font-weight: 700 !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #98A4AE !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 5px !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 4px !important; }

.rounded-2 {
  border-radius: 5px !important; }

.rounded-3 {
  border-radius: 5px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important; }

.rounded-end {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important; }

.rounded-bottom {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important; }

.rounded-start {
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 577px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 769px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 993px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1201px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1401px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3rem !important; }
  .fs-2 {
    font-size: 2.25rem !important; }
  .fs-3 {
    font-size: 1.875rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

body.dashboard .inner-wrapper {
  background-image: url("/assets/img/bg-mountains.jpg");
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat; }
  @media (max-width: 768.98px) {
    body.dashboard .inner-wrapper {
      background-size: auto; } }

body.dashboard .dashboard-header {
  padding: 40px 0; }
  @media (max-width: 768.98px) {
    body.dashboard .dashboard-header {
      padding: 30px 0 20px; } }
  body.dashboard .dashboard-header h2, body.dashboard .dashboard-header .h2,
  body.dashboard .dashboard-header .heading-xxlarge {
    font-size: 1.75em; }
    @media (max-width: 768.98px) {
      body.dashboard .dashboard-header h2, body.dashboard .dashboard-header .h2,
      body.dashboard .dashboard-header .heading-xxlarge {
        font-size: 1.25em; } }

body.dashboard .dashboard-plans-holder {
  width: 100%;
  height: 330px;
  overflow-y: auto; }
  body.dashboard .dashboard-plans-holder .plans-list {
    padding: 15px calc(30px / 2); }

body.login {
  position: relative;
  min-height: 100vh;
  padding-top: 60px;
  background: url("../img/bg-login.png") no-repeat bottom right/90vh, #1C355E; }
  @media (max-width: 768.98px) {
    body.login {
      padding-top: 30px;
      background: #1C355E; } }
  @media (max-width: 768.98px) {
    body.login .login-logo {
      width: 150px; } }
  body.login .login-footer {
    color: #fff;
    font-size: 0.875rem; }
    @media (max-width: 576.98px) {
      body.login .login-footer {
        text-align: center;
        font-size: 0.75rem; } }
    body.login .login-footer-links {
      margin-bottom: .65em; }
      body.login .login-footer-links > a {
        font-size: 0.875rem;
        color: #fff;
        text-decoration: none; }
        body.login .login-footer-links > a:not(:last-child) {
          margin-right: 2em; }
        @media (max-width: 576.98px) {
          body.login .login-footer-links > a {
            font-size: 0.75rem; } }
  body.login .new-mpo-block {
    display: none; }
    @media (max-width: 576.98px) {
      body.login .new-mpo-block {
        position: relative; } }
    body.login .new-mpo-block .new-mpo-headline {
      position: absolute;
      top: 28vh;
      right: 20vw; }
      @media (max-width: 1400.98px) {
        body.login .new-mpo-block .new-mpo-headline {
          right: 10vw; } }
      @media (max-width: 992.98px) {
        body.login .new-mpo-block .new-mpo-headline {
          display: none; } }
      @media (max-width: 576.98px) {
        body.login .new-mpo-block .new-mpo-headline {
          display: block !important;
          top: unset !important;
          right: unset !important;
          margin-top: 100px;
          min-height: 45vh; } }
      body.login .new-mpo-block .new-mpo-headline > p, body.login .new-mpo-block .new-mpo-headline h1, body.login .new-mpo-block .new-mpo-headline .h1,
      body.login .new-mpo-block .new-mpo-headline .heading-xxxlarge {
        text-align: right;
        color: #fff; }
        @media (max-width: 576.98px) {
          body.login .new-mpo-block .new-mpo-headline > p, body.login .new-mpo-block .new-mpo-headline h1, body.login .new-mpo-block .new-mpo-headline .h1,
          body.login .new-mpo-block .new-mpo-headline .heading-xxxlarge {
            text-align: left; } }
    body.login .new-mpo-block > img {
      display: none; }
      @media (max-width: 576.98px) {
        body.login .new-mpo-block > img {
          display: block; } }
  body.login.new-mpo {
    background: url("../img/bg-login-newmpo.png") no-repeat bottom right/contain, #1C355E; }
    @media (min-width: 769px) and (max-width: 1400.98px) {
      body.login.new-mpo {
        background: url("../img/bg-login-newmpo.png") no-repeat bottom right -15vw/contain, #1C355E; } }
    @media (min-width: 577px) and (max-width: 768.98px) {
      body.login.new-mpo {
        background: url("../img/bg-login-newmpo.png") no-repeat bottom right -30vw/contain, #1C355E; } }
    @media (max-width: 576.98px) {
      body.login.new-mpo {
        background: #1C355E; } }
    body.login.new-mpo .new-mpo-block {
      display: block; }
  body.login.new-mpo-fr {
    background: url("../img/bg-login-newmpo-fr.png") no-repeat bottom right/contain, #1C355E; }
    @media (min-width: 769px) and (max-width: 1400.98px) {
      body.login.new-mpo-fr {
        background: url("../img/bg-login-newmpo-fr.png") no-repeat bottom right -15vw/contain, #1C355E; } }
    @media (min-width: 577px) and (max-width: 768.98px) {
      body.login.new-mpo-fr {
        background: url("../img/bg-login-newmpo-fr.png") no-repeat bottom right -30vw/contain, #1C355E; } }
    @media (max-width: 576.98px) {
      body.login.new-mpo-fr {
        background: #1C355E; } }
    body.login.new-mpo-fr .new-mpo-block {
      display: block; }

body.reset-password {
  min-height: 100vh;
  padding-top: 60px;
  background: #1C355E; }
  body.reset-password .login-footer {
    color: #fff;
    font-size: 0.875rem;
    text-align: center; }
    @media (max-width: 576.98px) {
      body.reset-password .login-footer {
        font-size: 0.75rem; } }
    body.reset-password .login-footer-links {
      margin-bottom: .65em; }
      body.reset-password .login-footer-links > a {
        font-size: 0.875rem;
        color: #fff;
        text-decoration: none; }
        body.reset-password .login-footer-links > a:not(:last-child) {
          margin-right: 2em; }
        @media (max-width: 576.98px) {
          body.reset-password .login-footer-links > a {
            font-size: 0.75rem; } }

body.create-account {
  min-height: 100vh;
  padding-top: 30px;
  background: #1C355E; }
  body.create-account .login-footer {
    color: #fff;
    font-size: 0.875rem;
    text-align: center; }
    @media (max-width: 576.98px) {
      body.create-account .login-footer {
        font-size: 0.75rem; } }
    body.create-account .login-footer-links {
      margin-bottom: .65em; }
      body.create-account .login-footer-links > a {
        font-size: 0.875rem;
        color: #fff;
        text-decoration: none; }
        body.create-account .login-footer-links > a:not(:last-child) {
          margin-right: 2em; }
        @media (max-width: 576.98px) {
          body.create-account .login-footer-links > a {
            font-size: 0.75rem; } }

body.disclosureinfo .subpage-nav-aside {
  position: fixed;
  width: 20%;
  max-width: 300px; }

body.faq .card-myadvisor .card-body {
  padding-left: 0;
  padding-right: 0; }
  body.faq .card-myadvisor .card-body .myadvisor .myadvisor-item {
    width: 100%;
    padding: 30px; }
    @media (max-width: 576.98px) {
      body.faq .card-myadvisor .card-body .myadvisor .myadvisor-item {
        padding: 15px; } }
    body.faq .card-myadvisor .card-body .myadvisor .myadvisor-item:not(:first-child) {
      border-top: 1px solid rgba(255, 255, 255, 0.25); }

@media (min-width: 993px) {
  body.mysettings .mysettings-wrapper > div:last-child {
    border-left: 1px solid #C8CED3;
    padding-left: 0; } }

body.advisor .card-myadvisor .card-body {
  padding: 0; }
  body.advisor .card-myadvisor .card-body .myadvisor {
    overflow: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
    body.advisor .card-myadvisor .card-body .myadvisor .myadvisor-item {
      width: 100%;
      padding: 1rem 30px; }
      @media (max-width: 576.98px) {
        body.advisor .card-myadvisor .card-body .myadvisor .myadvisor-item {
          padding: 15px; } }
      body.advisor .card-myadvisor .card-body .myadvisor .myadvisor-item:not(:first-child) {
        border-top: 1px solid rgba(255, 255, 255, 0.25); }
