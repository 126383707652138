body.faq {
    .card-myadvisor {
        .card-body {
            padding-left: 0;
            padding-right: 0;
            .myadvisor {
                .myadvisor-item {
                    width: 100%;
                    padding: 30px;
                    @include media-breakpoint-down(sm) {
                        padding: 15px;
                    }
                    &:not(:first-child) {
                        border-top: 1px solid rgba($white, 0.25);
                    }
                }
            }
        }
    }
}