@font-face {
    font-family: 'peak-icons';
    src:  url('/assets/icons/peak-icons.eot?yz7so3');
    src:  url('/assets/icons/peak-icons.eot?yz7so3#iefix') format('embedded-opentype'),
      url('/assets/icons/peak-icons.ttf?yz7so3') format('truetype'),
      url('/assets/icons/peak-icons.woff?yz7so3') format('woff'),
      url('/assets/icons/peak-icons.svg?yz7so3#peak-icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="peak-icon-"], [class*=" peak-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'peak-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  //icons sizes helpers
  .i-size-xxs {font-size: .25rem !important;}
  .i-size-xs {font-size: .5rem !important;}
  .i-size-sm {font-size: .75rem !important;}
  .i-size-default {font-size: 1rem !important;}
  .i-size-md {font-size: 1.25rem !important;}
  .i-size-lg {font-size: 1.5rem !important;}
  .i-size-xl {font-size: 1.75rem !important;}
  .i-size-xxl {font-size: 2rem !important;}
  .i-size-xxxl {font-size: 2.25rem !important;}

  //circle rounded icon
  .i-circle-rounded {
    font-size: 1.125rem;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    color: $primary-blue;
    background-color: $primary-blue-100;
    transition: $transition-base;;
  }
  
  .peak-icon-arrow-switch:before {
    content: "\e945";
  }
  .peak-icon-star-hidden:before {
    content: "\e93d";
  }
  .peak-icon-star:before {
    content: "\e93e";
  }
  .peak-icon-list:before {
    content: "\e93f";
  }
  .peak-icon-eye-hidden:before {
    content: "\e940";
  }
  .peak-icon-eye:before {
    content: "\e941";
  }
  .peak-icon-filter:before {
    content: "\e942";
  }
  .peak-icon-client-circle:before {
    content: "\e943";
  }
  .peak-icon-target:before {
    content: "\e944";
  }
  .peak-icon-shield-money:before {
    content: "\e920";
  }
  .peak-icon-shield:before {
    content: "\e921";
  }
  .peak-icon-text-justified:before {
    content: "\e922";
  }
  .peak-icon-text-right:before {
    content: "\e923";
  }
  .peak-icon-text-center:before {
    content: "\e924";
  }
  .peak-icon-text-left:before {
    content: "\e925";
  }
  .peak-icon-folder-money:before {
    content: "\e926";
  }
  .peak-icon-search:before {
    content: "\e927";
  }
  .peak-icon-unlock:before {
    content: "\e928";
  }
  .peak-icon-contract:before {
    content: "\e929";
  }
  .peak-icon-file-upload:before {
    content: "\e92a";
  }
  .peak-icon-file-note:before {
    content: "\e92b";
  }
  .peak-icon-check-square:before {
    content: "\e92c";
  }
  .peak-icon-check-mark:before {
    content: "\e92d";
  }
  .peak-icon-bar-chart:before {
    content: "\e92e";
  }
  .peak-icon-pie-chart:before {
    content: "\e92f";
  }
  .peak-icon-plus:before {
    content: "\e930";
  }
  .peak-icon-clock:before {
    content: "\e931";
  }
  .peak-icon-mail-out:before {
    content: "\e932";
  }
  .peak-icon-mail-in:before {
    content: "\e933";
  }
  .peak-icon-calendar:before {
    content: "\e934";
  }
  .peak-icon-download:before {
    content: "\e935";
  }
  .peak-icon-upload:before {
    content: "\e936";
  }
  .peak-icon-client:before {
    content: "\e937";
  }
  .peak-icon-trash:before {
    content: "\e938";
  }
  .peak-icon-refresh:before {
    content: "\e939";
  }
  .peak-icon-map-marker:before {
    content: "\e93a";
  }
  .peak-icon-pencil:before {
    content: "\e93b";
  }
  .peak-icon-clients:before {
    content: "\e91f";
  }
  .peak-icon-lock:before {
    content: "\e91c";
  }
  .peak-icon-files:before {
    content: "\e91d";
  }
  .peak-icon-globe:before {
    content: "\e91e";
  }
  .peak-icon-suitcase:before {
    content: "\e91a";
  }
  .peak-icon-mail-check:before {
    content: "\e91b";
  }
  .peak-icon-phone:before {
    content: "\e919";
  }
  .peak-icon-linkedin:before {
    content: "\e918";
  }
  .peak-icon-arrow-right:before {
    content: "\e90f";
  }
  .peak-icon-chevron-right:before {
    content: "\e910";
  }
  .peak-icon-chevron-up:before {
    content: "\e911";
  }
  .peak-icon-arrow-down:before {
    content: "\e912";
  }
  .peak-icon-arrow-left:before {
    content: "\e914";
  }
  .peak-icon-arrow-up:before {
    content: "\e915";
  }
  .peak-icon-chevron-left:before {
    content: "\e916";
  }
  .peak-icon-chevron-down:before {
    content: "\e917";
  }
  .peak-icon-close:before {
    content: "\e913";
  }
  .peak-icon-advisor:before {
    content: "\e900";
  }
  .peak-icon-bar-chart-icon:before {
    content: "\e901";
  }
  .peak-icon-bell:before {
    content: "\e902";
  }
  .peak-icon-chat:before {
    content: "\e903";
  }
  .peak-icon-dashboard:before {
    content: "\e904";
  }
  .peak-icon-faq:before {
    content: "\e905";
  }
  .peak-icon-file-download:before {
    content: "\e906";
  }
  .peak-icon-folder:before {
    content: "\e907";
  }
  .peak-icon-gear:before {
    content: "\e908";
  }
  .peak-icon-mail:before {
    content: "\e909";
  }
  .peak-icon-pie-chart-icon:before {
    content: "\e90a";
  }
  .peak-icon-portfolio:before {
    content: "\e90b";
  }
  .peak-icon-power:before {
    content: "\e90c";
  }
  .peak-icon-printer:before {
    content: "\e90d";
  }
  .peak-icon-quotes:before {
    content: "\e90e";
  }
  