body.dashboard {
    .inner-wrapper {
        background-image: url('/assets/img/bg-mountains.jpg');
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        @include media-breakpoint-down(md) {
            background-size: auto;
        }
    }
    .dashboard-header {
        padding: 40px 0;
        @include media-breakpoint-down(md) {
            padding: 30px 0 20px;
        }
        h2 {
            font-size: 1.75em;
            @include media-breakpoint-down(md) {
                font-size: 1.25em;
            }
        }
    }
    .dashboard-plans-holder {
        width: 100%;
        height: 330px;
        overflow-y: auto;
        .plans-list {
            padding: $card-spacer-y calc(#{$card-spacer-x} / 2);
        }
    }
}