.timeline-v-chart {
    width: 100%;
    > ul {
        position: relative;
        list-style-type: none;
        margin: 0;
        padding: 1px 0;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 3px;
            height: 100%;
            background-color: $teal;
            z-index: 0;
            border-radius: 5px;
        }
        > li {
            display: flex;
            align-items: center;
            margin: 6px 0;
            > span {
                flex: 0 0 auto;
                width: calc(50% - 6px);
                font-size: $font-size-xs;
                &:first-child {
                    text-align: right;
                    padding-right: 8px;
                }
                &:last-child {
                    font-weight: $font-weight-bolder;
                    padding-left: 8px;
                }
            }
            .dot {          
                position: relative;
                z-index: 1;      
                flex: 0 0 auto;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: $teal;
                border: 2px solid $white;                
            }
        }
    }
}