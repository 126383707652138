// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav-header {
	position: fixed; z-index: 999;
	background-color: $dark;
	height: $nav-header-height;
	width: 100%;
	display: flex;
	align-items: center;
	@include media-breakpoint-down(md) {
		height: $nav-header-height-mobile;
	}
	.nav-header-inner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 30px;
		padding-left: 30px;
		@include media-breakpoint-down(md) {
			padding-right: 15px;
			padding-left: 0;
		}
		.nav-header-start {
			.nav-header-brand {
				@include media-breakpoint-down(md) {
					margin-left: 65px;
				}	
				> a {
					> img {
						width: 115px;
						@include media-breakpoint-down(md) {
							width: 100px;
						}
					}
				}
			}
			.nav-header-hamburger {
				display: block;
				height: 100%;
				position: absolute; left: 0; top: 0;
				padding: $hamburger-padding-y calc(#{$hamburger-padding-x} * 0.834);
				display: flex;
				align-items: center;				
				cursor: pointer;
		
				@include media-breakpoint-up(md){
					display: none;
				}
		
				transition-property: opacity, background-color;
				transition-duration: 0.15s;
				transition-timing-function: linear;
		
				// Normalize (<button>)
				font: inherit;
				color: inherit;
				text-transform: none;
				background-color: $primary-blue;
				border: 0;
				margin: 0;
				overflow: visible;
		
				.hamburger-box {
					width: $hamburger-layer-width;
					height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
					display: inline-block;
					position: relative;
				}
				.hamburger-inner {
					display: block;
					top: 50%;
					margin-top: $hamburger-layer-height / -2;
					transition-duration: 0.15s;
					transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		
					&,
					&::before,
					&::after {						
						width: $hamburger-layer-width;
						height: $hamburger-layer-height;
						background-color: $hamburger-layer-color;
						border-radius: $hamburger-layer-border-radius;
						position: absolute; left: 0;
						transition-property: transform;
						transition-duration: 0.1s;
						transition-timing-function: ease;
					}
					&::after {width: 8px;}
					
					& {
						width: 20px;
					}
		
					&::before,
					&::after {
						content: "";
						display: block;
					}
		
					&::before {
						top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
						transition: top 0.15s 0.12s ease,
						opacity 0.175s ease;
					}
		
					&::after {
						bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
						transition: bottom 0.15s 0.12s ease,
						transform 0.175s cubic-bezier(0.55, 0.055, 0.675, 0.19);
					}
				}
		
				&.active {
					background-color: $primary-blue;
		
					.hamburger-inner {
						transform: rotate(45deg);
						transition-delay: 0.12s;
						transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		
						& {
							width: $hamburger-layer-width;
						}
		
						&:before {
							top: 0;
							opacity: 0;
							transition: top 0.15s ease,
							opacity 0.15s 0.12s ease;
						}
		
						&::after {
							width: $hamburger-layer-width;
							bottom: 0;
							transform: rotate(-90deg);
							transition: bottom 0.15s ease,
							transform 0.15s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
						}
					}
		
					.hamburger-inner,
					.hamburger-inner::before,
					.hamburger-inner::after {
						background-color: $hamburger-active-layer-color;
					}
				}
			}
		}
		.nav-header-end {		
			> ul {
				list-style-type: none;
				display: flex;
				margin: 0;
				> li {
					> a {
						position: relative;
						display: flex;
						align-items: center;
						font-size: $font-size-xs;
						text-transform: uppercase;
						font-weight: $font-weight-bold;
						color: $gray-300;
						padding-left: 1.25em;
						padding-right: 1.25em;
						&:hover {
							color: $white;
						}
						&:after {
							content: '';
							position: absolute;
							right: 0;
							height: 30px;
							width: 1px;
							background-color: rgba($white, 0.25);
							@include media-breakpoint-down(md) {
								content: none;
							}
						}
						> i {
							font-size: 1.875em;
							vertical-align: middle;	
							margin-right: 3px;						
						}			
					}
					&:last-child {
						> a {
							padding-right: 0;
							&:after {
								content: none;
							}
						}
					}
					&.nav-header-notification {
						@include media-breakpoint-down(md) {
							order: 3;
							> a {	
								padding-right: 0;							
								> span {
									display: none;
								}
							}
						}
						.nav-header-notification-dropdown-menu {
							width: 320px !important;
							border-radius: 0;
							padding: 0;
							box-shadow: $box-shadow-lg;
							> li {
								&.nav-header-notification-menu-header {
									position: fixed;
									width: 100%;
									top: 0; left: 0;
									height: 40px;
									border-bottom: 1px solid $border-color;
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding: $dropdown-item-padding-y $dropdown-item-padding-x;
									> span {
										&:first-child {
											font-size: $font-size-sm;
											font-weight: $font-weight-bolder;
										}
										&:last-child {
											display: block;
											cursor: pointer;
											padding: 3px 10px;
											color: $primary-blue;
											text-transform: uppercase;
											font-size: $font-size-xs;
											font-weight: $font-weight-bolder;
											border-radius: $btn-border-radius;
											&:hover {
												background-color: $primary-blue-100;
											}
										}
									}
								}
								&.nav-header-notification-menu-wrapper {
									margin-top: 40px;
									padding: 5px 0 10px;
									max-height: 450px;
									overflow-y: auto;
									> ul {
										list-style-type: none;
										padding: 0;
										margin: 0;
										> li.nav-header-notification-menu-item {
											padding: 2px 5px;
											> a.dropdown-item {
												padding: 10px 15px;
												> div {
													color: $gray-dark;
													white-space: break-spaces;
													font-size: $font-size-sm;
													line-height: 1.125;
													font-weight: $font-weight-bold;
													> span {
														color: $primary-blue;
														font-weight: $font-weight-bolder;
													}										
												}
												> small {
													margin-top: 3px;
													display: block;
													line-height: 1;
													font-size: $font-size-xs;
													color: $dark;
												}
											}
											&.new {
												> a.dropdown-item {
													color: $dark;
													background-color: $primary-blue-100;
													&:hover {
														background-color: tint-color($primary-blue, 85%)
													}
												}
											}
										}
									}
								}							
							}
						}
						&.active {
							> a {
								color: $white;
								> i {
								position: relative;
									&:after {
										content: '';
										position: absolute;
										top: -7px; right: -5px;
										width: 18px; height: 18px;
										border-radius: 50%;
										border: 3px solid $dark;
										background-color: $fire;
									}
								}
							}
						}
					}			
					&.nav-header-logout, &.nav-header-faq {
						@include media-breakpoint-down(md) {
							display: none;
						}
					}
					&.nav-header-faq, &.nav-header-lang {
						> a {
							padding-top: .125rem;
							@include media-breakpoint-down(md) {
								padding-top: 0;
							}
						}
					}
					&.nav-header-chat {
						display: none;
						> a {
							> i {
								color: $white;
							}
						}
						@include media-breakpoint-down(md) {
							display: block;
							order: 2;
						}
						@media only screen and (max-width: 375px) {
							display: none;
						}
					}
					&.nav-header-lang {
						> a {
							@include media-breakpoint-down(md) {
								font-size: $font-size-sm;
							}
						}
					}
				}
			}
		}
	}
}

.nav-aside {
	width: $aside-nav-width;
	background-color: $darker;
	position: fixed; z-index: 999;
	left: 0; top: $nav-header-height; bottom: 0;
	height: calc(100vh - #{$nav-header-height});
	padding-top: 20px; padding-bottom: 30px;
	@include media-breakpoint-down(md) {
		display: none;
	}
	> ul {
		list-style-type: none;
		margin: 0; padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		> li {
			margin-bottom: 20px;
			> a {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0 5px;
				> i {
					width: 34px; height: 34px;
					border-radius: 50%;
					background-color: $gray-dark;
					color: $white;
					text-align: center;
					line-height: 34px;
					font-size: 1.35em;
					transition: $transition-base;
				}
				> span {
					display: block;
					margin-top: 5px;
					text-transform: uppercase;
					color: $light;
					font-size: 0.688rem;
					font-weight: $font-weight-bold;
					transition: $transition-base;
					white-space: initial;
    				text-align: center;
				}
				&:hover, &:focus, &.active {
					> i {
						background-color: $primary-blue;
					}
					> span {
						color: $white;
					}
				}
			}
			&:last-child {
				margin-top: auto;
				margin-bottom: 0;
			}
			.dropdown-menu {
				left: -3px !important;
				width: 320px;
				background-color: $dark;
				padding: 0;
				border-radius: 0;
				> li {
					> a {
						display: block;
						color: $white;
						text-transform: uppercase;
						font-weight: $font-weight-bold;
						font-size: $font-size-xs;
						padding: .5rem 20px;
						min-height: 36px;
						&:hover, &:focus {
							background-color: $darker;
						}					
					}
					&:not(:last-child) {
						> a {
							border-bottom: 1px solid rgba($white, 0.25);
						}
					}
				}
			}
		}
	}
}

.nav-mobile {
	position: fixed; z-index: 999;
	left: 0; top: $nav-header-height-mobile; bottom: 0;
	height: calc(100vh - #{$nav-header-height-mobile});
	padding-bottom: 30px;
	background-color: $darker;
	width: 100%;
	max-width: 420px;
	overflow-y: auto;
	transform: translateX(-420px);
	transition: transform .15s $easeInOutQuint;
	&.show {
		transform: translateX(0);
	}
	.nav-mobile-menu {
		list-style-type: none;
		margin: 0; padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		> li {
			> a {
				display: flex;
				align-items: center;
				height: 60px;
				padding: 15px;
				border-top: 1px solid $dark;
				> i {
					width: 30px; height: 30px;
					border-radius: 50%;
					background-color: $gray-dark;
					color: $white;
					text-align: center;
					line-height: 30px;
					font-size: 1.125em;
					transition: $transition-base;
				}
				> span {
					display: block;
					margin-left: 20px;
					text-transform: uppercase;
					color: $light;
					font-size: $font-size-sm;
					font-weight: $font-weight-bold;
					transition: $transition-base;
				}
				&:hover {
					background-color: $dark;
				}
				&:hover, &:focus, &.active {
					> i {
						background-color: $primary-blue;
					}
					> span {
						color: $white;
					}
				}
			}
			&:first-child {
				> a {
					border-top: 0;
				}
			}
			&.nav-mobile-menu-dropdown {
				transition: background-color .2s ease-in-out;
				> a {
					position: relative;
					&:after {
						content: "\e917";
						position: absolute;
						right: 30px;
						font-family: 'peak-icons';
						font-size: 1.125rem;
						transition: transform .2s ease-in-out;
						width: 30px; height: 30px;
						border-radius: 50%;
						line-height: 30px;
						text-align: center;
					}
					&[aria-expanded="true"] {
						background-color: $dark;
						&:after {
							transform: rotate(180deg);
							color: $white;
							background-color: $primary-blue;
						}
					}
				}
				.nav-mobile-menu-collpase {
					list-style-type: none;
					padding: 0; margin: 0;
					> li {
						> a {
							display: block;
							color: $white;
							text-transform: uppercase;
							font-weight: $font-weight-bold;
							font-size: $font-size-xs;
							padding: .5rem 20px;
							min-height: 36px;
							background-color: $dark;
						}
						&:not(:last-child) {
							> a {
								border-bottom: 1px solid rgba($white, .25);		
							}
						}
					}
				}
			}
		}
	}
	.nav-mobile-footer {
		list-style-type: none;
		margin: 30px 0 0 0; padding: 0;
		> li {
			> a {
				display: block;
				font-size: $font-size-sm;
				color: $gray-light;
				padding: 8px 15px;
			}
		}
	}
}

.subpage-nav-aside {
	overflow: hidden;
	@include media-breakpoint-down(md) {
		display: none;
	}
	> ul {
		list-style-type: none;
		padding: 0;
		> li {
			> a {
				position: relative;
				display: block;
				text-transform: uppercase;
				font-weight: $font-weight-bolder;
				color: $gray-dark;
				min-height: $input-btn-height;
				@include button-size(calc(#{$btn-padding-y} * 1.25), 1rem, $font-size-xs, 0);
				&.active {
					color: $primary-blue;
					background-color: $primary-blue-100;
					&::before {
						content: '';
						position: absolute;
						width: 4px;
						height: 100%;
						left: 0; top: 50%;
						transform: translateY(-50%);
						background-color: $primary-blue;
					}
				}
				&:hover, &:focus {
					color: $dark;
					background-color: $primary-blue-100;
				}
			}
		}
	}
	&.no-highlight {
		> ul {
			> li {
				> a {
					&.active {
						background-color: transparent;
					}
				}
			}
		}
	}
}
.subpage-nav-aside-mobile {	
	@include media-breakpoint-up(md) {
		display: none;
	}
	.subpage-nav-aside-mobile-button {
		display: flex;
		align-items: center;
		height: $input-btn-height-lg;
		background-color: $primary-blue-100;
		&[aria-expanded="true"] {
			.hamburger-inner {
				transform: rotate(45deg);
				transition-delay: 0.12s;
				transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

				& {
					width: $hamburger-layer-width;
				}

				&:before {
					top: 0;
					opacity: 0;
					transition: top 0.15s ease,
					opacity 0.15s 0.12s ease;
				}

				&::after {
					width: $hamburger-layer-width;
					bottom: 0;
					transform: rotate(-90deg);
					transition: bottom 0.15s ease,
					transform 0.15s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
				}
			}

			.hamburger-inner,
			.hamburger-inner::before,
			.hamburger-inner::after {
				background-color: $hamburger-active-layer-color;
			}
		}
	}
	.hamburger-box {
		width: $input-btn-height-lg;
		min-width: $input-btn-height-lg;
		height: $input-btn-height-lg;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		background-color: $primary-blue;
		border-top-left-radius: $card-border-radius;
	}
	.hamburger-inner {
		display: block;
		top: 50%;
		margin-top: $hamburger-layer-height / -2;
		transition-duration: 0.15s;
		transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

		&,
		&::before,
		&::after {						
			width: $hamburger-layer-width;
			height: $hamburger-layer-height;
			background-color: $hamburger-layer-color;
			border-radius: $hamburger-layer-border-radius;
			position: absolute;
		}

		&::before,
		&::after {
			content: "";
			display: block;
		}

		&::before {
			top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
			transition: top 0.15s 0.12s ease,
			opacity 0.175s ease;
		}

		&::after {
			bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
			transition: bottom 0.15s 0.12s ease,
			transform 0.175s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		}
	}

	.subpage-selection {
		width: 100%;
		padding: 10px 20px;
		text-transform: uppercase;
		font-size: $font-size-sm;
		color: $primary-blue;
		font-weight: $font-weight-bolder;
		@include text-truncate();
	}
	
	.dropdown-menu {
		width: 100% !important;
		border-radius: 0;
		> li {
			> a {
				position: relative;
				display: block;
				text-transform: uppercase;
				font-weight: $font-weight-bolder;
				color: $gray-dark;
				min-height: $input-btn-height;
				@include button-size(calc(#{$btn-padding-y} * 1.25), $btn-padding-x, $btn-font-size, 0);
				&.active {
					color: $primary-blue;
					background-color: $primary-blue-100;
					&::before {
						content: '';
						position: absolute;
						width: 4px;
						height: 100%;
						left: 0; top: 50%;
						transform: translateY(-50%);
						background-color: $primary-blue;
					}
				}
				&:hover, &:focus {
					color: $dark;
					background-color: $primary-blue-100;
				}
			}
		}
	}
}

.smallheader-nav-mobile {
	position: fixed;
	z-index: 998;
	width: 100%;
	top: $nav-header-height-mobile;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	height: $nav-smallheader-height;
	background-color: $white;
	border-bottom: 1px solid $border-color;
	@include media-breakpoint-up(md) {
		display: none;
	}
	.smallheader-nav-mobile-back {
		position: absolute;
		z-index: 2;
		left: 0;
		width: $nav-smallheader-height;
		height: $nav-smallheader-height;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		background-color: $white;
	}
	.smallheader-nav-mobile-button {
		position: relative;
		z-index: 1;
		width: 100%;
		height: $nav-smallheader-height;
		display: flex;
		align-items: center;
		padding: 0 3rem 0 1rem;
		> span {
			margin-left: $nav-smallheader-height;
			font-weight: $font-weight-bolder;
			font-size: $font-size-sm;
			@include text-truncate();
		}
		&:after {
			content: "\e917";
			position: absolute;
			color: $primary-blue;
			right: .5rem;
			font-family: 'peak-icons';
			font-size: 1.125rem;
			transition: transform .2s ease-in-out;
			width: 30px; height: 30px;
			border-radius: 50%;
			line-height: 30px;
			text-align: center;
		}
		&[aria-expanded="true"] {
			background-color: $primary-blue-100;
			border-bottom: 1px solid $border-color;
			&:after {
				transform: rotate(180deg);
				color: $white;
				background-color: $primary-blue;
			}
		}
	}
	.dropdown-menu {
		width: 100% !important;
		border-radius: 0;
		box-shadow: $box-shadow-lg;
		> li {
			.dropdown-header {
				font-size: $font-size-sm;
				font-weight: $font-weight-bolder;
			}
			.dropdown-item {
				position: relative;
				font-size: $font-size-xs;
				font-weight: $font-weight-bold;
				color: $gray-dark;
				border-bottom: 1px solid $border-color;
				padding: calc(#{$dropdown-item-padding-y} * 2) 2.25rem calc(#{$dropdown-item-padding-y} * 2) $dropdown-item-padding-x;
				@include text-truncate();
				&:hover,
				&:focus {
					color: $dropdown-link-hover-color;
					text-decoration: if($link-hover-decoration==underline, none, null);
					background-color: $dropdown-link-hover-bg;
				}
				&:after {
					content: "\e910";
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					color: $primary-blue;
					right: .5rem;
					font-family: 'peak-icons';
					font-size: 1.125rem;
					transition: transform .2s ease-in-out;
					width: 30px; height: 30px;
					border-radius: 50%;
					line-height: 30px;
					text-align: center;
				}
			}
			&:last-child {
				.dropdown-item {
					border-bottom: 0;
				}
			}
		}
	}
}
.smallheader-nav-mobile-spacer {
	display: block;
	width: 100%;
	height: $nav-smallheader-height;
	@include media-breakpoint-up(md) {
		display: none;
	}
}


.nav-link {
	display: block;
	padding: $nav-link-padding-y $nav-link-padding-x;
	@include font-size($nav-link-font-size);
	font-weight: $nav-link-font-weight;
	color: $nav-link-color;
	text-decoration: if($link-decoration==none, null, none);
	@include transition($nav-link-transition);

	&:hover,
	&:focus {
		color: $nav-link-hover-color;
		text-decoration: if($link-hover-decoration==underline, none, null);
	}

	// Disabled state lightens text
	&.disabled {
		color: $nav-link-disabled-color;
		pointer-events: none;
		cursor: default;
	}
}

//
// Tabs
//

.nav-tabs {
	.nav-link {
		position: relative;
		color: $gray-dark;
		text-transform: uppercase;
		font-size: $font-size-sm;
		font-weight: $font-weight-bolder;
		margin-bottom: -$nav-tabs-border-width;
		background: none;
		border: none;
		height: 60px;
		@include media-breakpoint-down(md) {
			font-size: $font-size-xs;
			height: 45px;
			padding: $nav-link-padding-y calc(#{$nav-link-padding-x} / 1.3333333333);
		}

		&:hover,
		&:focus {
			// Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
			isolation: isolate;
		}

		&.disabled {
			color: $nav-link-disabled-color;
			background-color: transparent;
			border-color: transparent;
		}
	}

	.nav-link.active,
	.nav-item.show .nav-link {
		color: $nav-tabs-link-active-color;
		&:after {
			content:'';
			position: absolute;
			left:0; bottom: 0; right: 0;
			width: 100%;
			height: 4px;
			background-color: $nav-tabs-link-active-color;
		}
	}

	.dropdown-menu {
		// Make dropdown border overlap tab border
		margin-top: -$nav-tabs-border-width;
		// Remove the top rounded corners here since there is a hard edge above the menu
		@include border-top-radius(0);
	}
}


//
// Pills
//

.nav-pills {
	.nav-link {
		background: none;
		border: 0;
		@include border-radius($nav-pills-border-radius);
	}

	.nav-link.active,
	.show>.nav-link {
		color: $nav-pills-link-active-color;
		@include gradient-bg($nav-pills-link-active-bg);
	}
}


//
// Justified variants
//

.nav-fill {

	>.nav-link,
	.nav-item {
		flex: 1 1 auto;
		text-align: center;
	}
}

.nav-justified {

	>.nav-link,
	.nav-item {
		flex-basis: 0;
		flex-grow: 1;
		text-align: center;
	}
}

.nav-fill,
.nav-justified {
	.nav-item .nav-link {
		width: 100%; // Make sure button will grow
	}
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
	>.tab-pane {
		display: none;
	}

	>.active {
		display: block;
	}
}
