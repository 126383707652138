//Default text size - 18px
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      .fs-xxlarge#{$infix} {
            font-size: $font-size-xxl !important;
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      .fs-xlarge#{$infix} {
            font-size: $font-size-xl !important;
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      .fs-large#{$infix} {
            font-size: $font-size-lg !important;
        }
    }
}

//Default text size - 16px
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      .fs-default#{$infix} {
            font-size: $font-size-base !important;
        }
    }
}

//small text size - 14px
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      .fs-small#{$infix} {
            font-size: $font-size-sm !important;
        }
    }
}
//xsmall text size - 12px
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      .fs-xsmall#{$infix} {
            font-size: $font-size-xs !important;
        }
    }
}