body.advisor {
    .card-myadvisor {
        .card-body {
            padding: 0;
            .myadvisor {
                overflow: hidden;
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
                .myadvisor-item {
                    width: 100%;
                    padding: 1rem 30px;
                    @include media-breakpoint-down(sm) {
                        padding: 15px;
                    }
                    &:not(:first-child) {
                        border-top: 1px solid rgba($white, 0.25);
                    }
                }
            }
        }
    }
}