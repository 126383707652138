/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 */

// Configuration
@import "core/functions";
@import "core/variables";
@import "core/mixins";
@import "core/utilities";

// Layout & components
@import "core/root";
@import "core/reboot";
@import "core/type";
@import "core/images";
@import "core/containers";
@import "core/grid";
@import "core/tables";
@import "core/forms";
@import "core/buttons";
@import "core/transitions";
@import "core/dropdown";
@import "core/button-group";
@import "core/nav";
@import "core/navbar";
@import "core/card";
@import "core/accordion";
@import "core/breadcrumb";
@import "core/pagination";
@import "core/badge";
@import "core/alert";
@import "core/progress";
@import "core/list-group";
@import "core/close";
@import "core/toasts";
@import "core/modal";
@import "core/tooltip";
@import "core/popover";
@import "core/carousel";
@import "core/spinners";
@import "core/offcanvas";
@import "core/placeholders";
@import "core/icons";
@import "core/layout";
@import "core/footer";
@import "core/myadvisor";
@import "core/charts";
@import "core/exchange-rate";

// Helpers
@import "core/helpers";

// Utilities
@import "core/utilities/api";

// Pages
@import "pages/dashboard";
@import "pages/login";
@import "pages/reset-password";
@import "pages/create-account";
@import "pages/disclosure-information";
@import "pages/faq";
@import "pages/mysettings";
@import "pages/myadvisor";
