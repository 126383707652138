body.login {
    position: relative;
    min-height: 100vh;
    padding-top: 60px;
    background: url('../img/bg-login.png') no-repeat bottom right/90vh, $dark;
    @include media-breakpoint-down(md) {
        padding-top: 30px;
        background: $dark;
    }
    .login-logo {
        @include media-breakpoint-down(md) {
            width: 150px;
        }
    }
    .login-footer {
        color: $white;
        font-size: $font-size-sm;
        @include media-breakpoint-down(sm) {
            text-align: center;
            font-size: $font-size-xs;
        }
        &-links {
            margin-bottom: .65em;
            > a {
                font-size: $font-size-sm;
                color: $white;
                text-decoration: none;
                &:not(:last-child) {
                    margin-right: 2em;
                }
                @include media-breakpoint-down(sm) {
                    font-size: $font-size-xs;
                }
            }
        }
    }    
    .new-mpo-block {
        display: none;
        @include media-breakpoint-down(sm) {
            position: relative;
        }
        .new-mpo-headline {       
            position: absolute;
            top: 28vh;
            right: 20vw;
            @include media-breakpoint-down(xxl) {
                right: 10vw;
            }
            @include media-breakpoint-down(lg) {
                display: none;
            }
            @include media-breakpoint-down(sm) {
                display: block !important;
                top: unset !important;
                right: unset !important;
                margin-top: 100px;
                min-height: 45vh;
            }
            > p, h1 {
                text-align: right;
                color: $white;
                @include media-breakpoint-down(sm) {
                    text-align: left;
                }
            }
        }
        > img {
            display: none;
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }
    }
    //NEW MPO background
    &.new-mpo {
        background: url('../img/bg-login-newmpo.png') no-repeat bottom right/contain, $dark;
        @include media-breakpoint-between(md, xxl) {
            background: url('../img/bg-login-newmpo.png') no-repeat bottom right -15vw /contain, $dark;
        }
        @include media-breakpoint-only(sm) {
            background: url('../img/bg-login-newmpo.png') no-repeat bottom right -30vw /contain, $dark;
        }
        @include media-breakpoint-between(xs, sm) {
            background: $dark;
        }    
        //SHOW NEW MPO - Block
        .new-mpo-block {
            display: block;
        }
    }
    //french image
    &.new-mpo-fr {
        background: url('../img/bg-login-newmpo-fr.png') no-repeat bottom right/contain, $dark;
        @include media-breakpoint-between(md, xxl) {
            background: url('../img/bg-login-newmpo-fr.png') no-repeat bottom right -15vw /contain, $dark;
        }
        @include media-breakpoint-only(sm) {
            background: url('../img/bg-login-newmpo-fr.png') no-repeat bottom right -30vw /contain, $dark;
        }
        @include media-breakpoint-between(xs, sm) {
            background: $dark;
        }    
        //SHOW NEW MPO - Block
        .new-mpo-block {
            display: block;
        }
    }    
}