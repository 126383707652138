// spacers (classes to help height spacing around elements)
$spaceamounts: (0,5,10,15,20,25,30,40,50,60,70,80,90,100,150,200,250);
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @each $space in $spaceamounts {
            .spacer#{$infix}-#{$space} {
                display: block;
                width: 100%;
                height: #{$space}px!important;
            }
        }
    }
}
