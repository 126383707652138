.footer {
    @include content-wrapper();
    width: 100%;
    height: 70px;
    margin-top: 150px;
    background-color: $white;
    border-top: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    @include media-breakpoint-between(xs, md) {
        height: auto;
        flex-direction: column;
        margin-top: 100px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .footer-disclaimer {
        color: $gray-dark;
        font-size: $font-size-sm;

        @include media-breakpoint-down(md) {
            margin-top: 30px;
            order: 2;
        }
    }

    .footer-menu {
        @include media-breakpoint-down(md) {
            order: 1;
            text-align: center;
            width: 100%;
        }

        > ul {           
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;

            > li {
                @include media-breakpoint-down(md) {
                   flex: 0 0 auto;
                   width: 33.333333%;
                }
                > a {
                    display: block;
                    color: $gray-dark;
                    font-size: $font-size-sm;
                    line-height: 1.25;

                    &:hover {
                        color: $gray;
                    }
                }

                &:not(:last-child) {
                    > a {
                        margin-right: 40px;

                        @include media-breakpoint-down(md) {
                            margin-right: 0;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}