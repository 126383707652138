@mixin content-wrapper {
    padding-left: 60px;
    padding-right: 60px;

    @include media-breakpoint-between(md, lg) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include media-breakpoint-between(xs, md) {
        padding-left: 15px;
        padding-right: 15px;
    }
}