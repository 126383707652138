body.reset-password {
    min-height: 100vh;
    padding-top: 60px;
    background: $dark;
    .login-footer {
        color: $white;
        font-size: $font-size-sm;
        text-align: center;
        @include media-breakpoint-down(sm) {
            font-size: $font-size-xs;
        }
        &-links {
            margin-bottom: .65em;
            > a {
                font-size: $font-size-sm;
                color: $white;
                text-decoration: none;
                &:not(:last-child) {
                    margin-right: 2em;
                }
                @include media-breakpoint-down(sm) {
                    font-size: $font-size-xs;
                }
            }
        }
    }
}