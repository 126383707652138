// The dropdown wrapper (`<div>`)
.dropup,
.dropend,
.dropdown,
.dropstart {
	position: relative;
}

.dropdown-toggle {
	white-space: nowrap;

	// Generate the caret automatically
	// @include caret();
}

// The dropdown menu
.dropdown-menu {
	position: absolute;
	z-index: $zindex-dropdown;
	display: none; // none by default, but block on "open" of the menu
	min-width: $dropdown-min-width;
	padding: $dropdown-padding-y $dropdown-padding-x;
	margin: 0; // Override default margin of ul
	@include font-size($dropdown-font-size);
	color: $dropdown-color;
	text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
	list-style: none;
	background-color: $dropdown-bg;
	background-clip: padding-box;
	border: $dropdown-border-width solid $dropdown-border-color;
	@include border-radius($dropdown-border-radius);
	@include box-shadow($dropdown-box-shadow);

	&[data-bs-popper] {
		top: 100%;
		left: 0;
		margin-top: $dropdown-spacer;
	}

	&.dropdown-menu-secondary {
		> li {
			.dropdown-item {
				text-transform: uppercase;
				font-size: $font-size-sm;
				font-weight: $font-weight-bolder;
				color: $gray-dark;
				border-bottom: 1px solid $border-color;
				padding: calc(#{$dropdown-item-padding-y} * 2) $dropdown-item-padding-x;
				&:hover,
				&:focus {
					color: $dropdown-link-hover-color;
					text-decoration: if($link-hover-decoration==underline, none, null);
					background-color: $dropdown-link-hover-bg;
				}
			}
			&:last-child {
				.dropdown-item {
					border-bottom: 0;
				}
			}
		}
	}
}

// scss-docs-start responsive-breakpoints
// We deliberately hardcode the `bs-` prefix because we check
// this custom property in JS to determine Popper's positioning

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.dropdown-menu#{$infix}-start {
			--bs-position: start;

			&[data-bs-popper] {
				right: auto;
				left: 0;
			}
		}

		.dropdown-menu#{$infix}-end {
			--bs-position: end;

			&[data-bs-popper] {
				right: 0;
				left: auto;
			}
		}
	}
}

// scss-docs-end responsive-breakpoints

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
	.dropdown-menu[data-bs-popper] {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: $dropdown-spacer;
	}

	.dropdown-toggle {
		// @include caret(up);
	}
}

.dropend {
	.dropdown-menu[data-bs-popper] {
		top: 0;
		right: auto;
		left: 100%;
		margin-top: 0;
		margin-left: $dropdown-spacer;
	}

	.dropdown-toggle {

		// @include caret(end);
		&::after {
			vertical-align: 0;
		}
	}
}

.dropstart {
	.dropdown-menu[data-bs-popper] {
		top: 0;
		right: 100%;
		left: auto;
		margin-top: 0;
		margin-right: $dropdown-spacer;
	}

	.dropdown-toggle {

		// @include caret(start);
		&::before {
			vertical-align: 0;
		}
	}
}


// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
	height: 0;
	margin: $dropdown-divider-margin-y 0;
	overflow: hidden;
	border-top: 1px solid $dropdown-divider-bg;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
	display: block;
	width: 100%; // For `<button>`s
	padding: $dropdown-item-padding-y $dropdown-item-padding-x;
	clear: both;
	font-weight: $font-weight-normal;
	color: $dropdown-link-color;
	text-align: inherit; // For `<button>`s
	text-decoration: if($link-decoration==none, null, none);
	// white-space: nowrap; // prevent links from randomly breaking onto new lines
	background-color: transparent; // For `<button>`s
	border: 0; // For `<button>`s

	// Prevent dropdown overflow if there's no padding
	// See https://github.com/twbs/bootstrap/pull/27703
	@if $dropdown-padding-y==0 {
		&:first-child {
			@include border-top-radius($dropdown-inner-border-radius);
		}

		&:last-child {
			@include border-bottom-radius($dropdown-inner-border-radius);
		}
	}

	&:hover,
	&:focus {
		color: $dropdown-link-hover-color;
		text-decoration: if($link-hover-decoration==underline, none, null);
		@include gradient-bg($dropdown-link-hover-bg);
	}

	&.active,
	&:active {
		color: $dropdown-link-active-color;
		text-decoration: none;
		@include gradient-bg($dropdown-link-active-bg);
	}

	&.disabled,
	&:disabled {
		color: $dropdown-link-disabled-color;
		pointer-events: none;
		background-color: transparent;
		// Remove CSS gradients if they're enabled
		background-image: if($enable-gradients, none, null);
	}
}

.dropdown-menu.show {
	display: block;
}

// Dropdown section headers
.dropdown-header {
	display: block;
	padding: $dropdown-header-padding;
	margin-bottom: 0; // for use with heading elements
	@include font-size($font-size-sm);
	color: $dropdown-header-color;
	white-space: nowrap; // as with > li > a
}

// Dropdown text
.dropdown-item-text {
	display: block;
	padding: $dropdown-item-padding-y $dropdown-item-padding-x;
	color: $dropdown-link-color;
}

// Dark dropdowns
.dropdown-menu-dark {
	color: $dropdown-dark-color;
	background-color: $dropdown-dark-bg;
	border-color: $dropdown-dark-border-color;
	@include box-shadow($dropdown-dark-box-shadow);

	.dropdown-item {
		color: $dropdown-dark-link-color;

		&:hover,
		&:focus {
			color: $dropdown-dark-link-hover-color;
			@include gradient-bg($dropdown-dark-link-hover-bg);
		}

		&.active,
		&:active {
			color: $dropdown-dark-link-active-color;
			@include gradient-bg($dropdown-dark-link-active-bg);
		}

		&.disabled,
		&:disabled {
			color: $dropdown-dark-link-disabled-color;
		}
	}

	.dropdown-divider {
		border-color: $dropdown-dark-divider-bg;
	}

	.dropdown-item-text {
		color: $dropdown-dark-link-color;
	}

	.dropdown-header {
		color: $dropdown-dark-header-color;
	}
}

.dropdown-sticky-header {
	position: fixed; z-index: 888;
	top: $nav-header-height;
	left: 0;
	width: 100%;
	@include media-breakpoint-down(md) {
		top: $nav-header-height-mobile;
	}
	.dropdown-toggle {
		width: 100%;
		height: $input-btn-height-lg;
		background-color: $white;
		border-bottom: 1px solid $border-color;
		padding: .5rem 3.5rem .5rem 1rem;
		display: flex;
		align-items: center;
		font-weight: $font-weight-bolder;
		position: relative;
		> span {
			display: block;
			@include text-truncate();
		}
		&:after {
			content: "\e917";
			position: absolute;
			right: 1rem;
			font-family: 'peak-icons';
			font-size: 1.125rem;
			transition: transform .2s ease-in-out;
			width: 30px; height: 30px;
			border-radius: 50%;
			line-height: 30px;
			text-align: center;
			color: $primary-blue;
			background-color: $primary-blue-100;
		}
		&[aria-expanded="true"] {
			&:after {
				transform: rotate(180deg);
				color: $white;
				background-color: $primary-blue;
			}
		}
	}
	
	.dropdown-menu {
		width: 100%;
		border-radius: 0;
	}
}
