.flatpickr-calendar {
    background: transparent;
    opacity: 0;
    display: none;
    text-align: center;
    visibility: hidden;
    padding: 0;
    -webkit-animation: none;
    animation: none;
    direction: ltr;
    border: 0;
    font-size: 12px;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: 320px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    background: #fff;
    -webkit-box-shadow: 0 0 25px rgba($darkest, 0.35);
    box-shadow: 0 0 25px rgba($darkest, 0.35);

    &.inline {
        opacity: 1;
        max-height: 640px;
        visibility: visible;
    }

    &.open {
        opacity: 1;
        max-height: 640px;
        visibility: visible;
        display: inline-block;
        z-index: 99999;
    }

    &.animate.open {
        -webkit-animation: none;
        animation: none;
    }

    &.inline {
        display: block;
        position: relative;
        top: 2px;
    }

    &.static {
        position: absolute;
        top: calc(100% + 2px);

        &.open {
            z-index: 999;
            display: block;
        }
    }

    &.multiMonth .flatpickr-days .dayContainer {
        &:nth-child(n + 1) .flatpickr-day.inRange:nth-child(7n + 7) {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

        &:nth-child(n + 2) .flatpickr-day.inRange:nth-child(7n + 1) {
            -webkit-box-shadow: -2px 0 0 $gray-200, 5px 0 0 $gray-200;
            box-shadow: -2px 0 0 $gray-200, 5px 0 0 $gray-200;
        }
    }

    .hasTime .dayContainer {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .hasWeeks .dayContainer {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
    }

    &.hasTime .flatpickr-time {
        height: 40px;
        border-top: 1px solid $border-color;
    }

    &.noCalendar.hasTime .flatpickr-time {
        height: auto;
    }

    &:after,
    &:before {
        position: absolute;
        display: block;
        pointer-events: none;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        left: 22px;
    }

    &.rightMost {
        &:after,
        &:before {
            left: auto;
            right: 22px;
        }
    }

    &:before {
        border-width: 5px;
        margin: 0 -5px;
    }

    &:after {
        border-width: 4px;
        margin: 0 -4px;
    }

    &.arrowTop {
        &:after {
            bottom: 100%;
        }

        &:before {
            bottom: 100%;
            border-bottom-color: $border-color;
        }

        &:after {
            border-bottom-color: #fff;
        }
    }

    &.arrowBottom {
        &:after {
            top: 100%;
        }

        &:before {
            top: 100%;
            border-top-color: $border-color;
        }

        &:after {
            border-top-color: #fff;
        }
    }

    &:focus {
        outline: 0;
    }
}

.flatpickr-wrapper {
    position: relative;
    display: inline-block;
}

.flatpickr-months {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    .flatpickr-month {
        background: transparent;
        color: rgba(0, 0, 0, 0.9);
        fill: rgba(0, 0, 0, 0.9);
        height: 50px;
        line-height: 1;
        text-align: center;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow: hidden;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .flatpickr-next-month,
    .flatpickr-prev-month {
        position: relative;
        text-decoration: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        padding: 2px;
        width: 50px; height: 50px;
        z-index: 3;
        color: rgba(0, 0, 0, 0.9);
        fill: rgba(0, 0, 0, 0.9);
        > svg {display: none;}
        &:before {
            font-family: 'peak-icons' !important;
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%,-50%);
            font-size: 1rem;
            color: $gray-500;
            background-color: $gray-200;
            width: 35px; height: 35px;
            line-height: 35px;
            border-radius: 2px;
        }
    }

    .flatpickr-prev-month {
        &:before {
            content: "\e916";
        }
    }
    .flatpickr-next-month {
        &:before {
            content: "\e910";
        }
    }

    .flatpickr-next-month.flatpickr-disabled,
    .flatpickr-prev-month.flatpickr-disabled {
        display: none;
    }

    .flatpickr-next-month i,
    .flatpickr-prev-month i {
        position: relative;
    }

    .flatpickr-next-month.flatpickr-prev-month,
    .flatpickr-prev-month.flatpickr-prev-month {
        /*
      /*rtl:begin:ignore*/
        left: 0;
        /*
      /*rtl:end:ignore*/
    }

    .flatpickr-next-month.flatpickr-next-month,
    .flatpickr-prev-month.flatpickr-next-month {
        /*
      /*rtl:begin:ignore*/
        right: 0;
        /*
      /*rtl:end:ignore*/
    }

    .flatpickr-next-month:hover,
    .flatpickr-prev-month:hover {
        color: $primary-blue;
    }

    .flatpickr-next-month:hover svg,
    .flatpickr-prev-month:hover svg {
        fill: #f64747;
    }

    .flatpickr-next-month svg,
    .flatpickr-prev-month svg {
        width: 14px;
        height: 14px;
    }

    .flatpickr-next-month svg path,
    .flatpickr-prev-month svg path {
        -webkit-transition: fill 0.1s;
        transition: fill 0.1s;
        fill: inherit;
    }
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.numInputWrapper {
    position: relative;
    height: auto;

    input,
    span {
        display: inline-block;
    }

    input {
        width: 100%;

        &::-ms-clear {
            display: none;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            margin: 0;
            -webkit-appearance: none;
        }
    }

    span {
        position: absolute;
        right: 0;
        width: 25px;
        padding: 0 4px 0 2px;
        height: 50%;
        line-height: 50%;
        opacity: 0;
        cursor: pointer;
        border: 1px solid rgba(57, 57, 57, 0.15);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        &:hover {
            background: $gray-200;
        }

        &:active {
            background: $gray-200;
        }

        &:after {
            display: block;
            content: "";
            position: absolute;
        }

        &.arrowUp {
            top: 0;
            border-bottom: 0;

            &:after {
                content: "\e911";
                font-family: 'peak-icons' !important;
                font-size: .85rem;
                color: $black;
                text-align: center;
                top: 26%;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.arrowDown {
            top: 50%;

            &:after {
                content: "\e917";
                font-family: 'peak-icons' !important;
                font-size: .85rem;
                color: $black;
                text-align: center;
                top: 38%;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        svg {
            width: inherit;
            height: auto;

            path {
                fill: rgba(0, 0, 0, 0.5);
            }
        }
    }

    &:hover {
        background: $gray-100;

        span {
            opacity: 1;
        }
    }
}

.flatpickr-current-month {
    font-size: 135%;
    line-height: inherit;
    font-weight: 300;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    line-height: 1;
    height: 50px;
    display: inline-block;
    text-align: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    margin-top: 5px;

    span.cur-month {
        font-family: inherit;
        font-weight: 700;
        color: inherit;
        display: inline-block;
        margin-left: 0.5ch;
        padding: 0;

        &:hover {
            background: rgba(0, 0, 0, 0.05);
        }
    }

    .numInputWrapper {
        width: 8ch;
        width: 7ch\0;
        display: inline-block;

        span {
            &.arrowUp:after {
                border-bottom-color: $border-color;
            }

            &.arrowDown:after {
                border-top-color: $border-color;
            }
        }
    }

    input.cur-year {
        background: transparent;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: inherit;
        cursor: text;
        padding: 0 0 0 0.5ch;
        margin: 0;
        display: inline-block;
        font-size: inherit;
        font-family: inherit;
        font-weight: $font-weight-bold;
        line-height: inherit;
        height: auto;
        border: 0;
        border-radius: 0;
        vertical-align: initial;
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
        height: 40px;

        &:focus {
            outline: 0;
        }

        &[disabled] {
            font-size: 100%;
            color: rgba(0, 0, 0, 0.5);
            background: transparent;
            pointer-events: none;

            &:hover {
                font-size: 100%;
                color: rgba(0, 0, 0, 0.5);
                background: transparent;
                pointer-events: none;
            }
        }
    }

    .flatpickr-monthDropdown-months {
        appearance: menulist;
        background: transparent;
        border: none;
        border-radius: 0;
        box-sizing: border-box;
        color: inherit;
        cursor: pointer;
        font-size: inherit;
        font-family: inherit;
        font-weight: 300;
        height: 40px;
        line-height: inherit;
        margin: -1px 0 0;
        outline: none;
        padding: 0 0 0 0.5ch;
        position: relative;
        vertical-align: initial;
        -webkit-box-sizing: border-box;
        -webkit-appearance: menulist;
        -moz-appearance: menulist;
        width: auto;
        font-weight: $font-weight-bold;

        &:active,
        &:focus {
            outline: none;
        }

        &:hover {
            background: $gray-100;
        }

        .flatpickr-monthDropdown-month {
            background-color: transparent;
            outline: none;
            padding: 0;
        }
    }
}

.flatpickr-weekdays {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 28px;

    .flatpickr-weekdaycontainer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
}

span.flatpickr-weekday {
    cursor: default;
    font-size: 90%;
    background: transparent;
    color: $gray-500;
    text-transform: uppercase;
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-weight: $font-weight-normal;
}

.dayContainer,
.flatpickr-weeks {
    padding: 1px 0 0;
}

.flatpickr-days {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 307.875px;

    &:focus {
        outline: 0;
    }
}

.dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: 307.875px;
    min-width: 307.875px;
    max-width: 307.875px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;

    + .dayContainer {
        -webkit-box-shadow: -1px 0 0 $gray-200;
        box-shadow: -1px 0 0 $gray-200;
    }
}

.flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: $black;
    cursor: pointer;
    font-weight: $font-weight-bold;
    width: 14.2857143%;
    -webkit-flex-basis: 14.2857143%;
    -ms-flex-preferred-size: 14.2857143%;
    flex-basis: 14.2857143%;
    max-width: 36px;
    height: 36px;
    line-height: 36px;
    margin: 0;
    display: inline-block;
    position: relative;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;

    &.inRange {
        cursor: pointer;
        outline: 0;
        background: $gray-200;
        border-color: $gray-200;
    }

    &.nextMonthDay {
        &.inRange,
        &.today.inRange,
        &:focus,
        &:hover {
            cursor: pointer;
            outline: 0;
            background: $gray-200;
            border-color: $gray-200;
        }
    }

    &.prevMonthDay {
        &.inRange,
        &.today.inRange,
        &:focus,
        &:hover {
            cursor: pointer;
            outline: 0;
            background: $gray-200;
            border-color: $gray-200;
        }
    }

    &.today.inRange,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: 0;
        background: $gray-200;
        border-color: $gray-200;
    }

    &.today {
        border-color: $primary-blue;

        &:focus,
        &:hover {
            border-color: $primary-blue;
            background: $primary-blue;
            color: #fff;
        }
    }

    &.endRange {
        background: #569ff7;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: #fff;
        border-color: #569ff7;

        &.inRange,
        &.nextMonthDay,
        &.prevMonthDay,
        &:focus,
        &:hover {
            background: #569ff7;
            -webkit-box-shadow: none;
            box-shadow: none;
            color: #fff;
            border-color: #569ff7;
        }
    }

    &.selected {
        background: $primary-blue;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: #fff;
        border-color: $primary-blue;

        &.inRange,
        &.nextMonthDay,
        &.prevMonthDay,
        &:focus,
        &:hover {
            background: $primary-blue;
            -webkit-box-shadow: none;
            box-shadow: none;
            color: #fff;
            border-color: $primary-blue
        }
    }

    &.startRange {
        background: #569ff7;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: #fff;
        border-color: #569ff7;

        &.inRange,
        &.nextMonthDay,
        &.prevMonthDay,
        &:focus,
        &:hover {
            background: #569ff7;
            -webkit-box-shadow: none;
            box-shadow: none;
            color: #fff;
            border-color: #569ff7;
        }
    }

    &.endRange.startRange,
    &.selected.startRange,
    &.startRange.startRange {
        border-radius: 50px 0 0 50px;
    }

    &.endRange.endRange,
    &.selected.endRange {
        border-radius: 0 50px 50px 0;
    }

    &.startRange.endRange {
        border-radius: 0 50px 50px 0;

        + .endRange:not(:nth-child(7n + 1)) {
            -webkit-box-shadow: -10px 0 0 #569ff7;
            box-shadow: -10px 0 0 #569ff7;
        }
    }

    &.selected.startRange + .endRange:not(:nth-child(7n + 1)),
    &.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
        -webkit-box-shadow: -10px 0 0 #569ff7;
        box-shadow: -10px 0 0 #569ff7;
    }

    &.endRange.startRange.endRange,
    &.selected.startRange.endRange,
    &.startRange.startRange.endRange {
        border-radius: 50px;
    }

    &.inRange {
        border-radius: 0;
        -webkit-box-shadow: -5px 0 0 $gray-200, 5px 0 0 $gray-200;
        box-shadow: -5px 0 0 $gray-200, 5px 0 0 $gray-200;
    }

    &.flatpickr-disabled {
        color: rgba(57, 57, 57, 0.3);
        background: transparent;
        border-color: transparent;
        cursor: default;

        &:hover {
            color: rgba(57, 57, 57, 0.3);
            background: transparent;
            border-color: transparent;
            cursor: default;
        }
    }

    &.nextMonthDay {
        color: rgba(57, 57, 57, 0.3);
        background: transparent;
        border-color: transparent;
        cursor: default;
    }

    &.notAllowed {
        color: rgba(57, 57, 57, 0.3);
        background: transparent;
        border-color: transparent;
        cursor: default;

        &.nextMonthDay,
        &.prevMonthDay {
            color: rgba(57, 57, 57, 0.3);
            background: transparent;
            border-color: transparent;
            cursor: default;
        }
    }

    &.prevMonthDay {
        color: rgba(57, 57, 57, 0.3);
        background: transparent;
        border-color: transparent;
        cursor: default;
    }

    &.flatpickr-disabled {
        cursor: not-allowed;
        color: rgba(57, 57, 57, 0.1);

        &:hover {
            cursor: not-allowed;
            color: rgba(57, 57, 57, 0.1);
        }
    }

    &.week.selected {
        border-radius: 0;
        -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
        box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
    }

    &.hidden {
        visibility: hidden;
    }
}

.rangeMode .flatpickr-day {
    margin-top: 1px;
}

.flatpickr-weekwrapper {
    float: left;

    .flatpickr-weeks {
        padding: 0 12px;
        -webkit-box-shadow: 1px 0 0 $gray-200;
        box-shadow: 1px 0 0 $gray-200;
    }

    .flatpickr-weekday {
        float: none;
        width: 100%;
        line-height: 28px;
    }

    span.flatpickr-day {
        display: block;
        width: 100%;
        max-width: none;
        color: rgba(57, 57, 57, 0.3);
        background: transparent;
        cursor: default;
        border: none;

        &:hover {
            display: block;
            width: 100%;
            max-width: none;
            color: rgba(57, 57, 57, 0.3);
            background: transparent;
            cursor: default;
            border: none;
        }
    }
}

.flatpickr-innerContainer {
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
}

.flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 40px;
    line-height: 40px;
    max-height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    .numInputWrapper {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 40%;
        height: 40px;
        float: left;

        span {
            &.arrowUp:after {
                border-bottom-color: $border-color;
            }

            &.arrowDown:after {
                border-top-color: $border-color;
            }
        }
    }

    &.hasSeconds .numInputWrapper {
        width: 26%;
    }

    &.time24hr .numInputWrapper {
        width: 49%;
    }

    input {
        background: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 0;
        border-radius: 0;
        text-align: center;
        margin: 0;
        padding: 0;
        height: inherit;
        line-height: inherit;
        color: #393939;
        font-size: 14px;
        position: relative;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;

        &.flatpickr-hour {
            font-weight: $font-weight-bold;
        }

        &.flatpickr-minute,
        &.flatpickr-second {
            font-weight: $font-weight-bold;
        }

        &:focus {
            outline: 0;
            border: 0;
        }
    }

    .flatpickr-am-pm,
    .flatpickr-time-separator {
        height: inherit;
        float: left;
        line-height: inherit;
        color: #393939;
        font-weight: bold;
        width: 2%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
    }

    .flatpickr-am-pm {
        outline: 0;
        width: 18%;
        cursor: pointer;
        text-align: center;
        font-weight: $font-weight-bold;

        &:focus,
        &:hover {
            background: $white;
        }
    }

    input {
        &:focus,
        &:hover {
            background: $white;
        }
    }
}

.flatpickr-input[readonly] {
    cursor: pointer;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}
@-webkit-keyframes fpFadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fpFadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
