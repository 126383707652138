//
// Headings
//
.h1,
.heading-xxxlarge {
	@extend h1; //48px
}

.h2,
.heading-xxlarge {
	@extend h2; //36px
}

.h3,
.heading-xlarge {
	@extend h3; //30px
}

.h4,
.heading-large {
	@extend h4; //24px
}

.h5,
.heading {
	@extend h5; //20px
}

.h6,
.heading-small {
	@extend h6; //18px
}


.lead {
	@include font-size($lead-font-size);
	font-weight: $lead-font-weight;
}

// Type display classes
@each $display,
$font-size in $display-font-sizes {
	.display-#{$display} {
		@include font-size($font-size);
		font-weight: $display-font-weight;
		line-height: $display-line-height;
	}
}

//
// Emphasis
//
.xsmall {
	@include font-size($xsmall-font-size);
}

.small {
	@extend small;
}

.mark {
	@extend mark;
}

//
// Lists
//

.list-unstyled {
	@include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
	@include list-unstyled();
}

.list-inline-item {
	display: inline-block;

	&:not(:last-child) {
		margin-right: $list-inline-padding;
	}
}

.list-style-numbered {
		list-style: none;
		counter-reset: my-awesome-counter;
		margin: 0;
		padding: 0;

	> li {
		position: relative;
		counter-increment: my-awesome-counter;
		padding-left: 1rem;
		&:before {
			position: absolute;
			top: 5px;
			left: -1rem;
			content: counter(my-awesome-counter)".";
			font-weight: $font-weight-bolder;
			margin-right: 0.5rem;
			line-height: 1;
		}
	}
}

.list-style-roman{
		list-style: none;
		counter-reset: my-awesome-counter;
		margin: 0;
		padding: 0;

	> li {
		position: relative;
		counter-increment: my-awesome-counter;
		padding-left: 1rem;
		&:before {
			position: absolute;
			top: 5px;
			left: -1rem;
			content: counter(my-awesome-counter, upper-roman)".";
			font-weight: $font-weight-bolder;
			margin-right: 0.5rem;
			line-height: 1;
		}
	}
}

.list-style-check{
		list-style: none;
		margin: 0;
		padding: 0;

	> li {
		position: relative;
		padding-left: 1rem;
		&:before {
			position: absolute;
			top: 8px;
			left: -1rem;
			font-size: .65em;
			content: "✔️";
			margin-right: 0.5rem;
			line-height: 1;
			color: $dark;
		}
	}
}

.list-style-alpha {
		list-style: none;
		counter-reset: my-awesome-counter;
		margin: 0;
		padding: 0;

	> li {
		position: relative;
		counter-increment: my-awesome-counter;
		padding-left: 1rem;
		&:before {
			position: absolute;
			top: 5px;
			left: -1rem;
			content: counter(my-awesome-counter, upper-alpha);
			font-weight: $font-weight-bolder;
			margin-right: 0.5rem;
			line-height: 1;
		}
	}
	
	&.rounded {
		> li {
			margin-bottom: .5em;
			&:before {
				top: 0;
				font-weight: $font-weight-bold;
				background-color: $dark;
				color: $white;
				border-radius: 50%;
				padding: .25em;				
				text-align: center;
				width: 1.5em; height: 1.5em;
			}
		}
	}
}


//
// Misc
//

// Builds on `abbr`
.initialism {
	@include font-size($initialism-font-size);
	text-transform: uppercase;
}

// Blockquotes
.blockquote {
	margin-bottom: $blockquote-margin-y;
	@include font-size($blockquote-font-size);

	> :last-child {
		margin-bottom: 0;
	}
}

.blockquote-footer {
	margin-top: -$blockquote-margin-y;
	margin-bottom: $blockquote-margin-y;
	@include font-size($blockquote-footer-font-size);
	color: $blockquote-footer-color;

	&::before {
		content: "\2014\00A0"; // em dash, nbsp
	}
}
