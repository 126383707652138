body.mysettings {
    .mysettings-wrapper {
        > div {
            &:last-child {
                @include media-breakpoint-up(lg) {
                    border-left: 1px solid $border-color;
                    padding-left: 0;
                }
            }
        }
    }
}